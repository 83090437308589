import { errorHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'
import { DateFilterParams } from '../types'
import {
  IProductYearlyReport,
  IProductYearlyReportQueryParams,
  ISalesDailyReport,
  ISalesMonthlyReport,
  SalesGoalAlignmentReportSalesMonthlyQueryParams,
} from './sales-report-types'

export const getReportSalesGoalDailyAPI = errorHandler<ISalesDailyReport>(async (params: DateFilterParams) => {
  return smartAuthAPI.get('/report-api/v1/sale-report/sales-daily', {
    params: { startDate: params.startDate, endDate: params.endDate },
  })
})

export const getReportSalesMonthlyDailyAPI = errorHandler<ISalesMonthlyReport>(
  async (params: SalesGoalAlignmentReportSalesMonthlyQueryParams) => {
    return smartAuthAPI.get('/report-api/v1/sale-report/sales-monthly', {
      params: { startMonthYear: params.startMonthYear, endMonthYear: params.endMonthYear },
    })
  },
)

export const getProductYearlyReportAPI = errorHandler<IProductYearlyReport[]>(
  async (params: IProductYearlyReportQueryParams) => {
    const response = await smartAuthAPI.get('/report-api/v1/sale-report/product-yearly-report', {
      params: { targetYear: params.targetYear },
    })
    return response?.data
  },
)
