/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Button, Grid, Tooltip } from '@material-ui/core'
import { ContactPhone, Edit, PictureAsPdf } from '@material-ui/icons'
import React, { createContext, useContext, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { QuotationProfile } from '../../../api/smartbestbuys-api/quotation/quotation-profile-types'
import {
  BreadcumbsList,
  CustomSpeedDial,
  FlexBox,
  Topic,
  TypographyWithLabel,
  withLayout,
} from '../../../custom-components'
import { mapCustomerProfileDomain } from '../../../data'
import { PageName, QuotationStatus } from '../../../enums'
import { useQuotationsProfile } from '../../../hooks'
import {
  CustomerProfileCard,
  Loading,
  NeedToFollowChip,
  QuotationRequestChangeSignal,
  QuotationStatusChip,
  Space,
} from '../../../shared-components'
import { QuotationsTaxInvoiceInformation } from '../QuotationCommon'
import QuotationsFollowCustomerDialog from '../QuotationCommon/QuotationsFollowCustomerDialog'
import QuotationProfileSelectedProduct from './QuotationProfileSelectedProduct'
import QuotationsManagementHistoryList from './QuotationsManagementHistoryList'
import QuotationsProfileCancelDialog from './QuotationsProfileCancelDialog'
import { QuotationsProfileDotMenu } from './QuotationsProfileDotMenu'
import { RootContext } from '../../..'
import SaleOrderRefBox from './SaleOrderRefBox'
import SaleOrderOfQuotationsList from './SaleOrderOfQuotationsList'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import QuotationUpdateAssigneeDialog from './QuotationUpdateAssigneeDialog'
import QuotationEvalutationDialog from './QuotationEvalutationDialog'
import QuotationsEvaluationResult from './QuotationsEvaluationResult'
import { CircularProgressWithLabel } from '../../../custom-components/Progress'

export interface QuotationsProfilePageContext {
  profile: QuotationProfile
}

export const QuotationsProfilePageContextImpl = createContext<QuotationsProfilePageContext>({
  profile: {} as QuotationProfile,
})

interface QuotationsProfilePageProps {
  id: number
}

const QuotationsProfilePage: React.FC<QuotationsProfilePageProps> = ({ id }) => {
  const { setCurrentPage } = useContext(RootContext)
  setCurrentPage(PageName.QUOTATION)

  const [quotationsFollowCustomerDialog, setQuotationsFollowCustomerDialog] = useState<boolean>(false)
  const [quotationsProfileCancelDialog, setQuotationsProfileCancelDialog] = useState<boolean>(false)
  const [updateAssigneeOpen, setUpdateAssigneeOpen] = useState(false)
  const [evalutationOpen, setEvalutation] = useState<boolean>(false)
  const [reload, setReload] = useState<boolean>(false)

  const toggleReload = () => setReload((prev) => !prev)

  const history = useHistory()

  const profile = useQuotationsProfile(id, reload)

  const writeSOPermission = getUserPermissionInfo(PermissionEnum.Sales_Quotation_Write)
  const ableToUpdateAssigneeUser = writeSOPermission.hasPermission

  const ableToUpdate = useMemo(() => {
    return profile ? [QuotationStatus.Pending, QuotationStatus.PendingReview].includes(profile.status.id) : false
  }, [profile?.status.id as number])

  const isPendingStatus = useMemo(() => {
    return profile ? profile.status.id === QuotationStatus.Pending : false
  }, [profile?.status.id as number])

  if (!profile) return <Loading />

  const countSaleOrder = profile.refSaleOrders?.length || 0

  const customerProfile = mapCustomerProfileDomain(
    profile.customer,
    profile.billingAddress ? [profile.billingAddress] : [],
    profile.contact ? [profile.contact] : [],
  )

  const assigneeName = profile.assigneeUser ? profile.assigneeUser.name : profile.recCreatedBy

  return (
    <QuotationsProfilePageContextImpl.Provider
      value={{
        profile,
      }}
    >
      <QuotationsProfileCancelDialog
        toggleReload={toggleReload}
        open={quotationsProfileCancelDialog}
        onClose={() => setQuotationsProfileCancelDialog(false)}
      />
      <QuotationsFollowCustomerDialog
        toggleReload={toggleReload}
        open={quotationsFollowCustomerDialog}
        onClose={() => setQuotationsFollowCustomerDialog(false)}
      />
      <QuotationUpdateAssigneeDialog
        open={updateAssigneeOpen}
        onClose={() => {
          setUpdateAssigneeOpen(false)
        }}
        onComplete={() => {
          setUpdateAssigneeOpen(false)
          toggleReload()
        }}
        quotation={{
          id: profile.id,
          assigneeUserId: profile.assigneeUser?.id,
        }}
      />
      <QuotationEvalutationDialog
        open={evalutationOpen}
        quotationId={profile.id}
        onClose={() => {
          setEvalutation(false)
        }}
        onComplete={() => {
          setEvalutation(false)
          toggleReload()
        }}
      />
      <Grid container spacing={2}>
        {profile.status.id !== QuotationStatus.PendingReview && (
          <CustomSpeedDial
            actions={[
              {
                icon: <PictureAsPdf />,
                name: 'ดูเป็น PDF',
                onClick: () => history.push(`/quotations/${id}/pdf`),
              },
              {
                icon: <ContactPhone />,
                name: 'ติดตามลูกค้า',
                onClick: () => setQuotationsFollowCustomerDialog(true),
                hidden: !isPendingStatus,
              },
            ]}
          />
        )}
        <Grid item xs={12}>
          <BreadcumbsList
            items={[{ url: '/quotations', name: 'รายการใบเสนอราคา' }, { name: profile?.code ?? '...' }]}
          />
        </Grid>
        <Grid item xs={12} container justify="space-between">
          <FlexBox alignItems="center" gridGap={10}>
            <Topic>ใบเสนอราคา {profile.code}</Topic>
            <FlexBox alignItems="center" className="pl-4 gap-1 cursor-help">
              {profile.evaluationResult ? (
                <CircularProgressWithLabel value={Math.ceil(profile.evaluationResult.evaluationScorePercent)} />
              ) : (
                <Button
                  onClick={() => {
                    setEvalutation(true)
                  }}
                  endIcon={<Edit color="secondary" />}
                >
                  ประเมินใบเสนอราคา
                </Button>
              )}

              <Button
                disabled={!ableToUpdateAssigneeUser}
                onClick={() => {
                  setUpdateAssigneeOpen(true)
                }}
                endIcon={<Edit color="secondary" />}
              >
                <Tooltip title="ผู้รับผิดชอบงาน">
                  <Avatar alt="Customer Avatar" style={{ width: 24, height: 24 }}></Avatar>
                </Tooltip>
                <span className="ml-2">{assigneeName}</span>
              </Button>
            </FlexBox>
            {profile.requestChanged && <QuotationRequestChangeSignal chip={true} />}
          </FlexBox>
          {ableToUpdate && (
            <QuotationsProfileDotMenu
              onClickEditQuotations={() => {
                history.push(`/quotations/${profile.id}/update`)
              }}
              onClickCancelQuotations={() => {
                setQuotationsProfileCancelDialog(true)
              }}
            />
          )}
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <FlexBox alignItems="center">
            <QuotationStatusChip status={profile.status.id} label={profile.status.name} icon />
            {profile.failureMessage && (
              <>
                <Space direction="horizontal" />
                <TypographyWithLabel label="สาเหตุ:">{profile.failureMessage}</TypographyWithLabel>
              </>
            )}
            {isPendingStatus && profile.needToFollow && (
              <>
                <Space direction="horizontal" />
                <NeedToFollowChip />
              </>
            )}
          </FlexBox>
        </Grid>
        {profile.status.id === QuotationStatus.Approve && (
          <Grid item xs={12}>
            <SaleOrderRefBox />
          </Grid>
        )}
        <Grid item xs={12}>
          <CustomerProfileCard
            contactId={profile.contact?.id ? Number(profile.contact.id) : undefined}
            customerProfile={customerProfile}
            billingAddressId={profile.billingAddress?.id ? profile.billingAddress.id : undefined}
            disableBtnCustomerDetail
          />
        </Grid>
        <Grid item xs={12}>
          <QuotationProfileSelectedProduct toggleReload={toggleReload} />
        </Grid>
        <Grid item xs={12}>
          <QuotationsTaxInvoiceInformation
            contactChannelId={profile.contactChannel?.id}
            paymentMethodId={profile.paymentMethod.id}
            currencyId={profile.currency.id}
            exchangeRate={profile.exchangeRate}
            expiredDate={new Date(profile.expiredDate)}
            remark={profile.remark}
            assigneeUser={profile.assigneeUser}
            dueDate={profile.dueDate ? new Date(profile.dueDate) : undefined}
          />
        </Grid>
        {countSaleOrder > 1 && (
          <Grid item xs={12}>
            <SaleOrderOfQuotationsList />
          </Grid>
        )}
        <Grid item xs={12}>
          <QuotationsManagementHistoryList />
        </Grid>
        {profile.evaluationResult && (
          <Grid item xs={12}>
            <QuotationsEvaluationResult />
          </Grid>
        )}
      </Grid>
    </QuotationsProfilePageContextImpl.Provider>
  )
}

export default withLayout(QuotationsProfilePage)
