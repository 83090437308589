import { CustomerPickUpMethod, SmartBankAccount } from '../../../types'
import { errorHandler } from '../../middleware'
import { ICustomerProfile_Address } from '../customer'
import { IPurchaseOrder, IUserPurchaseOrderActivity } from '../interfaces'
import smartAuthAPI from '../smartbestbuys-api'
import { TransportTaskStateV2, TransportTask_RelatedTask } from '../transport/transport-types'
import { BaseData, BaseDataWithEnum } from '../types'
import { IUser } from '../user'

export const getSaleOrderProfile = errorHandler<SaleOrderProfile>((id: number) => {
  return smartAuthAPI.get(`/sale-orders/${id}/profile`)
})

export interface SaleOrderProfile {
  id: number
  code: string
  quotationId?: number
  quotationCode?: string
  customer: SaleOrderCustomer
  customerPickUpMethod?: BaseDataWithEnum<CustomerPickUpMethod>
  contact?: SaleOrderContact
  billingAddress?: ICustomerProfile_Address
  contactChannel?: BaseData
  customerPurchaseReference?: string
  exchangeRate: number
  vatIncluded: boolean
  discountAmount?: number
  discountAmountType?: BaseData
  estimatedShippingDay?: number
  estimatedShippingFee?: number
  status: BaseData
  depositRequired: boolean
  remark?: string
  dueDate?: Date
  paymentDueDate?: Date
  isShippingFeeIncludingVat?: boolean
  createdAt: Date
  createdBy: string
  currency: BaseData
  paymentMethod: BaseData
  paymentCondition: BaseData
  paymentStatus: BaseData
  total: number
  productTotal: number
  products: SaleOrderProduct[]
  attachments?: SaleOrderAttachment[]
  documents?: SaleOrderDocument<any>[]
  activities?: SaleOrderActivity[]
  transportTasks?: SaleOrderProfile_TransportTask[]
  paymentTransactions?: SOPaymentTransaction[]
  purchaseRequisitions?: SOProfilePurchaseRequisition[]
  warehousePrepareProductFrontShopTasks?: SaleOrderProfile_Task[]
  newShippingDate?: Date
  failureMessage?: string
  customerRequisitions: SaleOrderProfile_CustomerRequisition[]
  refundRequisitions: SORefundRequisition[]
  checklistToCompleteCancel?: {
    reclaimProductBack: boolean // pending to get product back from customer
    createCreditNote: boolean // pending to create credit note for tax invoice
    refundToCustomer: boolean // pending to refund money back to customer
  }

  totalSellCost?: number
  assigneeUser?: Pick<IUser, 'id' | 'firstName' | 'lastName' | 'nickName'>
  nextFollowDate?: Date
  needToFollow: boolean

  purchaseOrders: (Pick<
    IPurchaseOrder,
    'id' | 'code' | 'vendorShippingDate' | 'status' | 'recCreatedAt' | 'recCreatedBy'
  > & {
    activities: Pick<
      IUserPurchaseOrderActivity,
      'id' | 'action' | 'actionMessage' | 'remark' | 'recCreatedAt' | 'recCreatedBy'
    >[]
  })[]
}

export interface SaleOrderProfile_Task {
  id: number
  code: string
  type: BaseData
  board: BaseData
  status: BaseData
  assignee?: BaseData
  dueDate?: Date
  state?: TransportTaskStateV2

  recCreatedAt: Date
  recCreatedBy: string
  finishedAt?: Date
  finishedBy?: string
}

export interface SaleOrderProfile_CustomerRequisition {
  id: number
  code: string
  content: string
  status: BaseData
  type: BaseData
  products: SaleOrderProfile_CustomerRequisitionProduct[]
  attachments: SaleOrderProfile_CustomerRequisitionAttachment[]
  notes: SaleOrderProfile_CustomerRequisitionNote[]
  recCreatedAt: Date
  recCreatedBy: string
}

export interface SaleOrderProfile_CustomerRequisitionProduct {
  id: number
  productId: number
  amount: number
  receivedAmount: number
  shippingAmount: number
  shippedAmount: number
}

export interface SaleOrderProfile_CustomerRequisitionAttachment {
  id: number
  url: string
  noteId?: number
}

export interface SaleOrderProfile_CustomerRequisitionNote {
  id: number
  content: string
  recCreatedAt: Date
  recCreatedBy: string
}

export interface SORefundRequisition {
  id: number
  code: string
  refundRequisitionId: number
  status: BaseData
  total: number
}

export interface SaleOrderActivity {
  id: number
  userId: number
  saleOrderId: number
  actionId: number
  actionName: string
  actionMessageId?: number
  actionMessage?: string
  remark?: string
  createdAt: Date
  createdBy: string
}

export interface SaleOrderProduct {
  id: number
  code: string
  saleOrderId: number
  productId: number
  nameLocal: string
  nameEn?: string
  unitLocal?: string
  unitEn?: string
  imageUrl?: string
  price: number
  amount: number
  shippedAmount: number
  reservedAmount: number
  stockAmount: number
  allReservedAmount: number
  // API version >= 0.7.6
  productTypeId: number
  // use in taxinvoice create dialog
  amountInDocument?: number
  // api version >= 0.11.1
  totalSellCost?: number
}

export interface SaleOrderDocument<DState> {
  id: number
  code: string
  type: BaseData
  status: BaseData
  total?: number
  referenceDocument?: SOReferenceDocument
  content?: object
  state?: DState
  cancellationInfo?: DocumentCancellationInfo
  recCreatedAt: Date
  recCreatedBy: string
  paymentTransaction?: SaleOrderDocument_SOPaymentTransaction
}

export interface SaleOrderDocument_SOPaymentTransaction {
  id: number
  code: string
}

export interface SOReferenceDocument {
  id: number
  code: string
}

export interface DocumentCancellationInfo {
  id: number
  name: string
  createdAt: Date
}

export interface SaleOrderAttachment {
  id: number
  saleOrderId: number
  attachmentUrl: string
}

export interface SaleOrderCustomer {
  id: number
  code: string
  customerTypeId: number
  businessTypeId?: number
  nameLocal: string
  nameEn?: string
  referenceNumber?: string
  phoneNumber: string
  websiteUrl: string
  creditPeriodDay?: number
  email?: string
  createdAt?: Date
  createdBy: string
}

export interface SaleOrderBillingAddress {
  id: number
  customerId: number
  name?: string
  branchName?: string
  addressLine1: string
  phoneNumber: string
  faxNumber?: string
  provinceId?: number
  province?: string
  districtId?: number
  district?: string
  subDistrictId?: number
  subDistrict: string
  postalCode?: string
  createdAt?: Date
  createdBy: string
}

export interface SaleOrderContact {
  id: number
  customerId: number
  nameLocal?: string
  nameEn?: string
  nicknameLocal: string
  nicknameEn?: string
  positionName?: string
  phoneNumber: string
  email?: string
  line?: string
  facebook?: string
  birthdate?: Date
  gender?: string
  createdAt?: Date
  createdBy: string
}

export interface SaleOrderProfile_TransportTask {
  id: number
  code: string
  type: BaseData
  board: BaseData
  status: BaseData
  assignee?: BaseData
  dueDate?: Date
  state?: TransportTaskStateV2

  recCreatedAt: Date
  recCreatedBy: string
  finishedAt?: Date
  finishedBy?: string

  subTasks: TransportTask_RelatedTask[]
  failureShippingMessage?: string
  nextShippingDate?: Date
}

export interface SOPaymentTransaction {
  id: number
  code: string
  status: BaseData
  amount: number
  state: SOPaymentTransactionState
  paymentMethod: BaseData
  paymentImageUrl?: string
  paymentAdjustment?: PaymentAdjustment
  withholdingAmount?: number
  withholdingTaxDocumentReceived?: boolean
  bank?: BaseData
  bankAccount?: SmartBankAccount
  statementVerifiedAt?: Date
  statementVerifiedBy?: string
  statementTimestamp?: Date
  paymentTimestamp?: Date
  recCreatedAt: Date
  recCreatedBy: string
  rejectedMessage?: string
  rejectedAt?: Date
  referenceDocuments?: SOPaymentTransaction_Document[]
}

export interface SOPaymentTransaction_Document {
  id: number
  code: string
  typeId: number
}

export interface PaymentAdjustment {
  amount: number
  typeId: number
  type: string
}

export interface SOPaymentTransactionState {
  referenceNumber?: string
  paymentTimestamp?: Date
  branchName?: string
  verificationRemark?: string
}

export interface SOProfilePurchaseRequisition {
  id: number
  code: string
  dueDate?: Date
  status: BaseData
  latestVerifiedAt?: Date
  latestVerifiedBy?: string
  recCreatedAt: Date
  recCreatedBy: string
  purchaseOrders?: SOPurchaseOrder[]
}

export interface SOPurchaseOrder {
  id: number
  code: string
  purchaseRequisitionId: number
  status: BaseData
  latestVerifiedAt?: Date
  latestVerifiedBy?: string
  recCreatedBy: string
  recCreatedAt: Date
  products: SOPurchaseOrderProduct[]
}

export interface SOPurchaseOrderProduct {
  id: number // product_id
  purchaseOrderId: number
  imageUrl?: string
  name: string
  code: string
  unit?: string
  type: BaseData
  amount: number
  price: number
  receivedAmount: number
}
