import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogProps,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core'
import { useContext } from 'react'
import { RootContext } from '../../..'
import { NestedValue, useForm } from 'react-hook-form'
import { Form } from '../../../custom-components'
import { IQuotationEvalutationForm_Question, quotationEvaluationForm } from '../../../constants/quotation'
import { ButtonRadios } from '../../../shared-components'
import { evalutationQuotationAPI, IEvaluationQuotationAnswer } from '../../../api/smartbestbuys-api'

interface QuotationEvalutationDialogFromValues {
  quotationId: number
  answers: NestedValue<Array<IEvaluationQuotationAnswer>>
  evaluationScore: number
  evaluationScorePercent: number
}

interface Props extends DialogProps {
  quotationId: number
  onComplete: () => void
}

const QuotationEvalutationDialog: React.FC<Props> = (props: Props) => {
  const { quotationId, onComplete, onClose, ...otherProps } = props
  const { triggerSnackbar } = useContext(RootContext)

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm<QuotationEvalutationDialogFromValues>({
    defaultValues: {
      quotationId,
      answers: quotationEvaluationForm.questions.map((data) => ({
        question: data.question,
        name: '',
        score: 0,
      })),
    },
  })

  const { answers } = watch()

  const handleAnswerChoice = (index: number, name: string, score: number) => {
    clearErrors('answers')
    setValue(`answers.${index}.name`, name)
    setValue(`answers.${index}.score`, score)
  }

  const handleSubmitQuotationPartialUpdateForm = async (values: QuotationEvalutationDialogFromValues) => {
    const isAnswerEveryQuestion = answers.every((a) => a.name)

    if (!isAnswerEveryQuestion) {
      setError('answers', {
        type: 'required',
        message: 'กรุณาตอบให้ครบทุกคำถาม',
      })
    } else {
      if (!!errors.answers) {
        clearErrors('answers')
      }

      const score = values.answers.reduce<number>((total, item) => {
        return (total += item.score)
      }, 0)

      const scorePercent = Math.ceil((score / quotationEvaluationForm.maxScore) * 100)

      const response = await evalutationQuotationAPI(quotationId, {
        answers: values.answers,
        evaluationScore: score,
        evaluationScorePercent: scorePercent,
      })

      if (response?.status === 201) {
        triggerSnackbar('ประเมินใบเสนอราคาสำเร็จ')
        if (!!onComplete) onComplete()
      } else {
        triggerSnackbar('ประเมินใบเสนอราคาไม่สำเร็จ')
        if (!!onClose) onClose({}, 'escapeKeyDown')
      }
    }
  }

  return (
    <Dialog fullWidth maxWidth="md" {...otherProps} className="p-4">
      <Form onSubmit={handleSubmit(handleSubmitQuotationPartialUpdateForm)}>
        <DialogTitle>
          <div className="text-4xl">ประเมินโอกาสในการเสนอราคา</div>
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              {quotationEvaluationForm.questions.map((question, index) => (
                <QuotationEvalutationCard data={question} index={index} onCheck={handleAnswerChoice} />
              ))}
            </Grid>
          </Grid>
          {errors.answers && (
            <Typography color="error" variant="caption">
              {errors.answers.message}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button type="button" color="primary" onClick={() => onClose && onClose({}, 'escapeKeyDown')}>
            ปิด
          </Button>
          <Button type="submit" color="primary" variant="contained" disabled={isSubmitting}>
            บันทึก
          </Button>
        </DialogActions>
      </Form>
    </Dialog>
  )
}

export default QuotationEvalutationDialog

interface QuotationEvalutationCardProps {
  data: IQuotationEvalutationForm_Question
  index: number
  onCheck: (index: number, name: string, score: number) => void
}

const QuotationEvalutationCard: React.FC<QuotationEvalutationCardProps> = ({ data, index, onCheck }) => {
  return (
    <div className="my-6">
      <div className="text-2xl mb-4">
        {index + 1}. {data.question}
      </div>
      <div>
        <ButtonRadios
          items={data.choices.map((choice) => ({
            id: choice.score,
            value: choice.score,
            name: choice.name,
          }))}
          onValueChange={(value) => {
            const choice = data.choices.find((choice) => choice.score === value)
            if (!!choice) onCheck(index, choice.name, Number(value))
          }}
        />
      </div>
    </div>
  )
}
