import { useContext, useState } from 'react'
import { Avatar, Button, Card, Grid, Paper, Typography } from '@material-ui/core'
import { Edit, Photo } from '@material-ui/icons'
import { EmptyCard, FlexBox, Title } from '../../../../../custom-components'
import ProductProfileContext from '../ProductProfileContext'
import { ConditionTooltip } from '../../../../../shared-components'
import { RootContext } from '../../../../..'
import { getUserPermissionInfo } from '../../../../../utils/permission'
import { PermissionEnum } from '../../../../../enums/PermissionEnum'
import './PairProduct.less'
import PairProductSelectorDialog from './PairProductSelectorDialog'
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints'

interface Props {
  width: Breakpoint
}

const PairedProductList: React.FC<Props> = ({ width }) => {
  const [opened, setOpened] = useState<boolean>(false)
  const { productProfile, reload } = useContext(ProductProfileContext)
  const { triggerSnackbar } = useContext(RootContext)

  const writePermission = getUserPermissionInfo(PermissionEnum.Warehouse_Product_Write)
  const isShowPairProduct = writePermission.hasPermission && !productProfile.recDeletedAt

  const handleSuccess = () => {
    triggerSnackbar(`จัดการสินค้าที่เกี่ยวข้องสำเร็จ`)
    setOpened(false)
    reload()
  }

  const handleFail = () => {
    triggerSnackbar(`จัดการสินค้าที่เกี่ยวข้องไม่สำเร็จ`)
    setOpened(false)
  }

  return (
    <>
      {opened && (
        <PairProductSelectorDialog
          width={width}
          productProfile={productProfile}
          open={opened}
          onClose={() => setOpened(false)}
          onSuccess={handleSuccess}
          onFail={handleFail}
        />
      )}
      <Paper>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <Title>รายการสินค้าที่เกี่ยวข้อง</Title>
              {isShowPairProduct && (
                <ConditionTooltip title={writePermission.alertMessage} showTooltip={!writePermission.hasPermission}>
                  <Button
                    color="primary"
                    startIcon={<Edit />}
                    onClick={() => {
                      setOpened(true)
                    }}
                    disabled={!writePermission.hasPermission}
                  >
                    เลือกสินค้าที่เกี่ยวข้อง
                  </Button>
                </ConditionTooltip>
              )}
            </FlexBox>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {productProfile.pairedProducts.length > 0 ? (
            <Grid container spacing={2}>
              {productProfile.pairedProducts.map((product) => {
                return (
                  <Grid item xs={12} md={3}>
                    <Card>
                      <div className="product-pair__product-cell" key={'product-' + product.id}>
                        <Avatar alt={product.nameLocal} src={product.imageUrl} variant="rounded">
                          <Photo />
                        </Avatar>
                        <div className="product-pair__product-cell__info">
                          <Typography color="textSecondary" variant="body2">
                            {product.code}
                          </Typography>
                          <Typography variant="body2">{product.nameLocal}</Typography>
                        </div>
                      </div>
                    </Card>
                  </Grid>
                )
              })}
            </Grid>
          ) : (
            <EmptyCard emptyText="ไม่มีข้อมูลสินค้าที่เกี่ยวข้อง" />
          )}
        </Grid>
      </Paper>
    </>
  )
}

export default PairedProductList
