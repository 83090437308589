import { startOfDay } from 'date-fns'
import {
  GetProductMustToPurchaseSearchParams,
  ProductMustToPurchase,
  getProductMustToPurchaseTableDataAPI,
} from '../../api/smartbestbuys-api'
import { useTableDataV2 } from '../useTablaData'

const _buildQueryParams = (_s: ProductMustToPurchase.SearchParams): GetProductMustToPurchaseSearchParams => {
  return {
    nameLocal: _s.nameLocal,
    code: _s.code,
    grades: _s?.grades?.join(','),
    outOfStockDate: _s.outOfStockDate ? startOfDay(_s.outOfStockDate).toISOString() : undefined,
    vendorName: _s.vendorName,
  }
}

export const useProductMustToPurchaseTableData = () =>
  useTableDataV2<
    ProductMustToPurchase.Data,
    {},
    ProductMustToPurchase.SearchParams,
    GetProductMustToPurchaseSearchParams
  >(0, 10, { grades: [] }, getProductMustToPurchaseTableDataAPI, _buildQueryParams)
