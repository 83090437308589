import { Dialog, DialogContent, DialogProps, DialogTitle } from '@material-ui/core'
import { useContext, useMemo } from 'react'
import { RootContext } from '../../..'
import {
  updateMyUserInfoAPI,
  UpdateUserInfoRequestBody,
  updateUserInformationByIdAPI,
  UserProfile,
} from '../../../api/smartbestbuys-api'
import { UserInformationUpdateForm, UserInformationUpdateFormValues } from '.'
import { getUser } from '../../../functions'

interface Props extends DialogProps {
  user: UserProfile
  onComplete?: (data: UserProfile) => void
}

export const UserInformationUpdateDialog = (props: Props) => {
  const { triggerSnackbar } = useContext(RootContext)
  const { user, onComplete, onClose, ...dialogProps } = props

  const userInfo = getUser()

  const isMyUserProfile = useMemo(
    () => {
      return userInfo?.id === user.id
    }, // eslint-disable-next-line
    [user?.id],
  )

  const handleSubmit = async (values: UserInformationUpdateFormValues) => {
    const request: UpdateUserInfoRequestBody = {
      nickName: user.nickName,
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: values.birthDate,
      email: values.email,
      phoneNumber: values.phoneNumber,
      base64: values.base64,
      imageUrl: values.imageUrl,
    }
    try {
      const response = isMyUserProfile
        ? await updateMyUserInfoAPI(request)
        : await updateUserInformationByIdAPI(user.id, request)
      if (response && response.status === 200) {
        triggerSnackbar('แก้ไขข้อมูลทั่วไปสำเร็จ')
        if (onComplete) onComplete(response.data)
        window.location.reload()
      } else {
        triggerSnackbar('แก้ไขข้อมูลทั่วไปไม่สำเร็จ')
      }
    } catch (error) {
      triggerSnackbar('แก้ไขข้อมูลทั่วไปไม่สำเร็จ')
    }
  }

  return (
    <Dialog {...dialogProps} fullWidth maxWidth="lg">
      <DialogTitle>แก้ไขข้อมูลทั่วไป</DialogTitle>
      <DialogContent>
        <UserInformationUpdateForm
          user={user}
          onSubmit={handleSubmit}
          onCancel={() => onClose && onClose({}, 'escapeKeyDown')}
        />
      </DialogContent>
    </Dialog>
  )
}
