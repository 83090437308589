import { format } from 'date-fns'
import { errorHandler, errorSingleHandler } from '../../middleware'
import {
  GetProductROPTableDataRequestQueryParams,
  GetProductTableDataRequestQueryParams,
  IProductAnalyticReportTableData,
  IProductChangeData,
  IProductMustToPurchaseTableData,
  IProductProfile,
  IProductROPTableData,
  IProductTableData,
  IProductTransaction,
  ProductAnalyticTableDataQueryParams,
  ProductMustToPurchaseQueryParams,
  ProductWithROPInfo,
  RequestToUpdateProductRequestBody,
  UpdateProductStockAmountRequestBody,
} from './product-types'
import { ProductProfile } from './get-product-profile-api'
import { DateTimeFilterParams, IStatusResponse, UserActivityNote } from '../types'
import { APIResponse } from '../interfaces'
import smartAuthAPI from '../smartbestbuys-api'

interface GetProductTransactionsQueryParams {
  startDate: Date
  endDate: Date
}

export const fetchProductsWithROPInfo = errorHandler<ProductWithROPInfo[]>(async () => {
  return smartAuthAPI.get('/products/pending-to-purchase')
})

export const getProductTransactions = errorHandler<IProductTransaction[]>(
  async (query: GetProductTransactionsQueryParams) => {
    return smartAuthAPI.get('/products/transactions', {
      params: {
        startDate: format(query.startDate, 'yyyy-MM-dd HH:mm:ss'),
        endDate: format(query.endDate, 'yyyy-MM-dd HH:mm:ss'),
      },
    })
  },
)

export const deleteProductAPI = errorSingleHandler<ProductProfile>(async (id: number, request: UserActivityNote) => {
  return smartAuthAPI.patch(`products/${id}/delete`, request)
})

export const restoreProductAPI = errorSingleHandler<ProductProfile>(async (id: number, request: UserActivityNote) => {
  return smartAuthAPI.patch(`products/${id}/restore`, request)
})

export const getProductPaginationAPI = errorHandler<IProductTableData>(
  async (queryParams: GetProductTableDataRequestQueryParams) => {
    return await smartAuthAPI.get(`/products/table-data`, {
      params: queryParams,
    })
  },
)

export const getProductROPPaginationAPI = errorHandler<IProductROPTableData>(
  async (queryParams: GetProductROPTableDataRequestQueryParams) => {
    return await smartAuthAPI.get(`/products/rop-table-data`, {
      params: queryParams,
    })
  },
)

export const verifyProductDataAPI = errorSingleHandler<IStatusResponse>(async (id: number) => {
  return smartAuthAPI.post(`products/${id}/verify-data`)
})

export const getProductPreviewDataAPI = errorSingleHandler<IProductChangeData>(async (id: number) => {
  return smartAuthAPI.get(`products/${id}/data-changed`)
})

export const approveRequestChangeProductAPI = errorSingleHandler<IStatusResponse>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`products/${id}/approve-request-change`, request)
  },
)

export const rejectRequestChangeProductAPI = errorSingleHandler<IStatusResponse>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`products/${id}/reject-request-change`, request)
  },
)

export const requestToUpdateProductInformationAPI = errorSingleHandler<IStatusResponse>(
  (id: number, request: RequestToUpdateProductRequestBody) => {
    return smartAuthAPI.post(`products/${id}/request-to-update`, request)
  },
)

export const updateProductPairAPI = errorSingleHandler<{ success: boolean }>(
  (
    id: number,
    request: {
      pairProductIds: number[]
    },
  ) => {
    return smartAuthAPI.post(`products/${id}/update-product-pair`, request)
  },
)

export const getProductProfileAPIV2 = errorSingleHandler<IProductProfile>(
  async (id: number, params: DateTimeFilterParams) => {
    return await smartAuthAPI.get(`api/v2/products/${id}/profile`, {
      params: {
        startDateTime: params.startDateTime,
        endDateTime: params.endDateTime,
      },
    })
  },
)

export const getProductAnalyticTableDataAPI = errorHandler<IProductAnalyticReportTableData>(
  async (queryParams: ProductAnalyticTableDataQueryParams) => {
    return await smartAuthAPI.get(`/report-api/v1/product-report/analytic-table-data`, {
      params: queryParams,
    })
  },
)

export const updateProductStockAmount = errorSingleHandler<{ stockAmount: number }>(
  (id: number, request: UpdateProductStockAmountRequestBody) => {
    const path = `api/v2/products/${id}/update-stock-amount`
    return smartAuthAPI.patch(path, request)
  },
)

export const getProductMustToPurchaseTableDataAPI = errorHandler<APIResponse<IProductMustToPurchaseTableData>>(
  async (queryParams: ProductMustToPurchaseQueryParams) => {
    const response = await smartAuthAPI.get(`api/v2/products/must-to-purchase-table-data`, {
      params: queryParams,
    })

    return response
  },
)
