import {
  PurchaseOrderUpdateData,
  PurchaseOrderUpdateRequest,
  PurchaseOrderUpdateRequest_Product,
} from '../../../api/smartbestbuys-api'
import { calculatePurchaseOrderTotal } from '../../../functions'
import { FileType, SourceFile } from '../../../types'
import PurchaseOrderCreateFormValues from '../create/PurchaseOrderCreateFormValues'

export const mapPurchaseOrderDataToFormValues = (data: PurchaseOrderUpdateData): PurchaseOrderCreateFormValues => {
  const { purchaseOrderInfo } = data
  const attachments = purchaseOrderInfo.attachments?.map<SourceFile>((attachment) => {
    const fileName = attachment.attachmentUrl.split('/')
    return {
      type: attachment.attachmentUrl.slice(-4) === '.pdf' ? FileType.PDF : FileType.Image,
      base64: attachment.attachmentUrl,
      name: fileName[fileName.length - 1],
    }
  })

  const vendorCountry =
    !!data.purchaseOrderInfo.vendorInfo?.country && data.purchaseOrderInfo.vendorInfo.country !== 'ไทย'
      ? 'other'
      : 'ไทย'

  const purchaseOrderProducts = purchaseOrderInfo.products.map((product) => ({
    ...product,
    name: vendorCountry === 'ไทย' ? product.name : product.nameEn,
    unit: vendorCountry === 'ไทย' ? product.unit : product.unitEn,
    oldProductName: product.name,
    oldUnit: product.unit,
  }))

  return {
    discountAmount: purchaseOrderInfo.discountAmount,
    withholdingTaxPercentage: purchaseOrderInfo.withholdingTaxPercentage,
    paymentConditionId: purchaseOrderInfo.paymentCondition.id,
    paymentMethodId: purchaseOrderInfo.paymentMethod.id,
    vendorQuotationCode: purchaseOrderInfo.vendorQuotationCode,
    vendorShippingDate: purchaseOrderInfo.vendorShippingDate,
    remark: purchaseOrderInfo.remark,
    attachments,
    depositAmount: purchaseOrderInfo.depositAmount,
    vatIncluded: !!purchaseOrderInfo.vatIncluded,
    vendor: purchaseOrderInfo.vendorInfo,
    products: [...purchaseOrderProducts] as any,
    sendCustomerShippingAddressById: false,
    shippingByVendor: false, // TODO: check from DB
  }
}

export const mapPurchaseOrderToUpdateFormValues = (
  value: PurchaseOrderCreateFormValues,
  remark: string,
): PurchaseOrderUpdateRequest => {
  const { products, vendor } = value
  const productTotal = products.reduce<number>((sum, product) => {
    return sum + product.price * product.amount
  }, 0)

  const total = calculatePurchaseOrderTotal(productTotal, value.discountAmount, value.vatIncluded)

  const vendorCountry = !!vendor?.country && vendor.country !== 'ไทย' ? vendor.country : 'ไทย'

  const parseProducts = products.map<PurchaseOrderUpdateRequest_Product>((product) => ({
    ...product,
    name: vendorCountry === 'ไทย' ? product.name : product?.oldProductName ?? product.name,
    nameEn: vendorCountry !== 'ไทย' ? product.name : product.nameEn,
    unit: vendorCountry === 'ไทย' ? product.unit : product.oldUnit,
    price: product.price,
    typeId: product.type?.id || product.typeId!,
  }))

  const attachments = value.attachments?.map((attachment) => {
    return {
      base64: attachment.base64,
    }
  })

  return {
    paymentConditionId: value.paymentConditionId,
    paymentMethodId: value.paymentMethodId,
    vendorQuotationCode: value.vendorQuotationCode,
    withholdingTaxPercentage: value.withholdingTaxPercentage,
    vatIncluded: !!value.vatIncluded,
    shippingByVendor: false,
    depositAmount: value.depositAmount,
    total,
    productTotal,
    discountAmount: value.discountAmount,
    remark,
    products: parseProducts,
    exchangeRate: value.exchangeRate ?? 1,
    attachments,
  }
}
