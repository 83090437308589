export enum QuotationStatus {
  Pending = 1,
  Approve = 2,
  Cancelled = 3,
  Rejected = 4,
  PendingReview = 5,
}

export enum EvaluaionType {
  Default = '0',
  NoEvaluation = '1',
  Low = '2',
  Middle = '3',
  Medium = '4',
  Interested = '5',
  HighReadiness = '6',
}
