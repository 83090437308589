import { useState, useEffect } from 'react'
import { BaseData, getActionMessagesByUserAction } from '../api/smartbestbuys-api'
import { UserAction } from '../enums'
import { getActionMessagesFromLocalStorage, setActionMessageToLocalStorage } from '../functions'
import { add } from 'date-fns'

export const useActionMessages = (userAction: UserAction, skip?: boolean): BaseData[] => {
  const [messages, setMessages] = useState<BaseData[]>([])

  const _getActionMessagesByUserAction = async (userAction: UserAction) => {
    const actionMessagesStorage = getActionMessagesFromLocalStorage(userAction)
    if (!actionMessagesStorage || new Date().getTime() > actionMessagesStorage.expiredTimes) {
      const response = await getActionMessagesByUserAction(userAction)
      const actionMessageData = response?.data ?? []

      const expiredTimes = add(new Date(), {
        hours: 4,
      }).getTime()

      setMessages(actionMessageData)
      setActionMessageToLocalStorage(userAction, actionMessageData, expiredTimes)
    } else {
      setMessages(actionMessagesStorage.actionMessages)
    }
  }

  useEffect(() => {
    if (skip) return
    _getActionMessagesByUserAction(userAction)
  }, [userAction, skip])

  return messages
}
