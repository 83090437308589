import { Document, Page, Image, Text, Font, View } from '@react-pdf/renderer'
import React from 'react'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
} from '../../../../shared-components'
import { styles } from './PWTicketPDFStyle'
import smartBestBuysLogo from '../../../../assets/images/new-logo.png'
import { dateFormat } from '../../../../functions'
import { RouteComponentProps } from 'react-router-dom'
import numeral from 'numeral'
import { useProductWithdrawalTicketProfile } from '../../../../hooks'
import { IProductWithdrawalTicketProduct } from '../../../../api/smartbestbuys-api/warehouse'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const PWTicketPDF: React.FC<RouteComponentProps<any>> = (props) => {
  const { id } = props.match.params
  const profile = useProductWithdrawalTicketProfile(id)
  if (!profile.data) return <Loading />

  const { data } = profile

  const { code, products, recCreatedAt, recCreatedBy, requestedDate, estimatedReturnedDate, remark } = data

  document.title = `คำขอเบิกสินค้าเลขที่ ${data.code}`

  const showedImagesPage = !!products.find((p) => !!p.imageUrl)

  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>ที่อยู่: </Text>519/174-176 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ
                    กรุงเทพฯ 10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>โทร: </Text>02-1147006
                    </Text>
                    <Text>
                      <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>อีเมล: </Text>sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="เลขที่คำขอ" direction="row" dataWidth={88} noMargin>
                {code}
              </PDFInfo>
              {recCreatedAt && (
                <PDFInfo label="วันที่สร้างคำขอ" direction="row" dataWidth={88} noMargin>
                  {dateFormat(recCreatedAt)}
                </PDFInfo>
              )}
              <PDFInfo label="วันที่ต้องการสินค้า" direction="row" dataWidth={88} noMargin>
                {dateFormat(requestedDate)}
              </PDFInfo>
              {estimatedReturnedDate && (
                <PDFInfo label="วันที่คาดว่าจะคืนสินค้า" direction="row" dataWidth={88} noMargin>
                  {dateFormat(estimatedReturnedDate)}
                </PDFInfo>
              )}
              <PDFInfo label="ผู้ขอเบิกสินค้า" direction="row" dataWidth={88} noMargin>
                {recCreatedBy}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            ใบคำขอเบิกสินค้า
          </Text>
          {/* SubHeader */}
          {/* <PDFRow borderColor="light">
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ชื่อลูกค้า">{customer?.nameLocal || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เลขประจำตัวผู้เสียภาษี">{customer?.referenceNumber || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ที่อยู่">{billingAddress ? getAddressText(billingAddress) : '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ผู้ติดต่อ">{contact?.nicknameLocal || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรศัพท์">{customer?.phoneNumber || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรสาร">{billingAddress?.faxNumber || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="อีเมล">{customer?.email || '-'}</PDFInfo>
            </PDFColumn>
          </PDFRow> */}

          <PDFTable>
            {/* Head Table */}
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                ลำดับ
              </PDFTableCell>
              <PDFTableCell head width={72}>
                รหัสสินค้า
              </PDFTableCell>
              <PDFTableCell head width={380}>
                รายละเอียด
              </PDFTableCell>
              <PDFTableCell head width={84} textAlign="right">
                จำนวน
              </PDFTableCell>
            </PDFTableRow>
            {/* Content Table */}
            {products.map((product: IProductWithdrawalTicketProduct, index: number) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCell width={72}>{product.code}</PDFTableCell>
                <PDFTableCell width={380}>{product.nameLocal}</PDFTableCell>
                <PDFTableCell width={84} textAlign="right">
                  {numeral(product.amount).format('0,0')} {product.unitLocal}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          {/* Footer Table */}
          {/* <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(quotationProfile.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="หมายเหตุ" color="error">
                  {quotationProfile.remark}
                </PDFInfo>
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {priceBreakdowns
                .filter((price) => {
                  return price.amount > 0 || !['ส่วนลด', 'ค่าขนส่ง'].includes(price.name)
                })
                .map((price) => (
                  <PDFInfo key={price.name} label={price.name} direction="row" dataWidth={160}>
                    {numeral(price.amount).format('0,0.00')}
                  </PDFInfo>
                ))}
            </PDFView>
          </PDFView> */}

          {/* <PDFView bottomLine="lightGrey" style={{ padding: '8px 0px', fontSize: 12 }}>
            <Text>*** ซื้อครบ 499 บาท ส่งมอบสินค้าภายในกรุงเทพ และ ปริมณฑล ฟรี!!</Text>
            <Text>
              *** ต่างจังหวัด ลูกค้าเป็นผู้ออกค่าขนส่ง ทางบริษัทฯมีบริการส่งสินค้าตามบริษัทขนส่งที่ลูกค้ากำหนด
            </Text>
            <Text>
              *** กรุณาตรวจสอบรายการสินค้าก่อนการสั่งซื้อ ทางบริษัทฯไมมีนโยบายรับคืนสินค้าหากมีการเปิดใบสั่งซื้อมาแล้ว
            </Text>
            <Text>*** นโยบายการคืนสินค้า</Text>
            <Text>
              1. บริษัทฯ ยินดีรับคืนสินค้าจากปัญหาการสั่งซื้อ หรือจัดส่งผิดพลาด จากบริษัทฯ ภายใน 5 วันทำการ
              นับจากวันที่ลูกค้าได้รับมอบสินค้า โดยสินค้าและหีบห่อบรรจุภัณฑ์จะต้องอยู่ในสภาพสมบูรณ์
              ไม่มีร่องรอยการใช้งาน ทางลูกค้าต้องมีใบส่งสินค้าจากการสั่งซื้อเพื่ออ้างอิงวันที่การสั่งซื้อ ทั้งนี้
              ทางบริษัทฯ ขอสงวนสิทธิ์ไม่รับประกันสินค้า หรือ คืนสินค้าหากไม่มีใบส่งสินค้าในทุกกรณี
            </Text>
            <Text>
              2. สินค้าที่ลูกค้าสั่งผลิตตามแบบโดยเฉพาะเจาะจง หรือที่บริษัทฯ จัดหาให้ลูกค้าเป็นการเฉพาะ บริษัทฯ
              ขอสงวนสิทธิ์ในการรับคืนสินค้าดังกล่าว
            </Text>
          </PDFView> */}

          {/* <PDFRow borders={['bottom']} borderColor="light" align="flex-start">
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เงื่อนไขการชำระเงิน">{quotationProfile.paymentMethod.name}</PDFInfo>
            </PDFColumn>
          </PDFRow> */}

          <View wrap={false}>
            <PDFRow>
              <PDFColumn columnSpan={12}>
                <Text style={{ paddingBottom: 24, width: '100%', textAlign: 'left' }}>วิธีการขนส่งสินค้า:</Text>
                <Text
                  style={{
                    paddingBottom: 4,
                    width: '100%',
                    textAlign: 'left',
                    border: ' none',
                    borderTop: '1px dotted #000',
                    color: '#fff',
                    backgroundColor: '#fff',
                    height: '1px',
                  }}
                />
              </PDFColumn>
            </PDFRow>
            <PDFRow>
              <PDFColumn columnSpan={12}>
                <PDFInfo label="หมายเหตุ" color="error">
                  {remark}
                </PDFInfo>
              </PDFColumn>
            </PDFRow>
            <PDFRow>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView title="ลงนาม/ผู้ประทับตรา ผู้ขอเบิกสินค้า" titlePaddingBottom={24} />
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView title="ลงนามผู้รับเรื่อง" titlePaddingBottom={24} />
              </PDFColumn>
            </PDFRow>
            <PDFRow>
              <PDFColumn columnSpan={6}>
                <View style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                  <Text style={{ paddingBottom: 24, width: '100%', textAlign: 'center' }}>ลงนามซัพพลายเออร์</Text>
                  <Text style={{ paddingBottom: 16 }}>..............................................</Text>
                  <Text style={{ paddingBottom: 8 }}>(.................................................)</Text>
                  <Text>
                    {'วันที่'} {'........./........./...............'}
                  </Text>
                </View>
              </PDFColumn>
            </PDFRow>
          </View>

          <PDFPagination />
        </Page>
        {showedImagesPage && (
          <Page size="A4" style={styles.page}>
            <Text style={styles.title} fixed>
              ภาพสินค้าทั้งหมดจากคำขอเบิกสินค้า
            </Text>

            <PDFRow align="flex-start">
              {products
                .filter((p) => !!p.imageUrl)
                .map((product: IProductWithdrawalTicketProduct, index: number) => (
                  <PDFColumn columnSpan={4} key={index}>
                    <Image
                      style={styles.imageProductGallery}
                      src={{
                        uri: product.imageUrl!,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                        body: '',
                      }}
                    />
                    <PDFInfo label={product.code}>
                      <Text>{product.nameLocal}</Text>
                    </PDFInfo>
                  </PDFColumn>
                ))}
            </PDFRow>
            <PDFPagination />
          </Page>
        )}
      </Document>
    </SoraPDFViewer>
  )
}

export default PWTicketPDF
