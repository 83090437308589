import React, { FC } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font, View } from '@react-pdf/renderer'
import numeral from 'numeral'
import { ThaiBaht } from 'thai-baht-text-ts'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
} from '../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../assets/images/logo.jpg'
import lineContact from '../../../../assets/images/line-contact.png'
import { useSaleOrderProfile } from '../../../../hooks'
import { dateFormat, getAddressText, getSOPricebreakdown } from '../../../../functions'
import { SaleOrderProduct } from '../../../../api/smartbestbuys-api'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 52,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 272,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 12,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
  title: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
  },
  imageProductGallery: {
    height: 150,
    width: 'auto',
    paddingRight: 20,
  },
})

const SaleOrderPDF: FC<RouteComponentProps<any>> = (props) => {
  const { id: saleOrderId } = props.match.params
  const saleOrderProfile = useSaleOrderProfile(saleOrderId)
  if (!saleOrderProfile) return <Loading />

  const priceBreakdowns = getSOPricebreakdown(saleOrderProfile)

  const { code, products, customer, contact, billingAddress, createdAt, createdBy, customerPurchaseReference } =
    saleOrderProfile

  document.title = `ใบสั่งขาย ${saleOrderProfile.code}`

  const showedImagesPage = !!products.find((p) => !!p.imageUrl)

  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>ที่อยู่: </Text>519/174-176 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ
                    กรุงเทพฯ 10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>โทร: </Text>02-1147006
                    </Text>
                    <Text>
                      <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>อีเมล: </Text>sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="เลขที่" direction="row" dataWidth={88} noMargin>
                {code}
              </PDFInfo>
              <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                {dateFormat(createdAt)}
              </PDFInfo>
              <PDFInfo label="ผู้จัดทำเอกสาร" direction="row" dataWidth={88} noMargin>
                {createdBy}
              </PDFInfo>
              <PDFInfo label="เลขที่ PO ของลูกค้า" direction="row" dataWidth={88} noMargin>
                {customerPurchaseReference ?? '-'}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            ใบสั่งขาย
          </Text>
          {/* SubHeader */}
          <PDFRow borderColor="light">
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ชื่อลูกค้า">
                {billingAddress?.overrideDocumentData?.customerName || customer?.nameLocal}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เลขประจำตัวผู้เสียภาษี">
                {billingAddress?.overrideDocumentData?.taxNumber || customer?.referenceNumber}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ที่อยู่">
                {billingAddress?.overrideDocumentData?.address ||
                  (billingAddress ? getAddressText(billingAddress) : '-')}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ผู้ติดต่อ">{contact?.nicknameLocal}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรศัพท์">{customer?.phoneNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรสาร">{billingAddress?.faxNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="อีเมล">{customer?.email || '-'}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <PDFTable>
            {/* Head Table */}
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                ลำดับ
              </PDFTableCell>
              <PDFTableCell head width={72}>
                รหัสสินค้า
              </PDFTableCell>
              <PDFTableCell head width={212}>
                รายละเอียด
              </PDFTableCell>
              <PDFTableCell head width={64} textAlign="right">
                จำนวน
              </PDFTableCell>
              <PDFTableCell head width={80} textAlign="right">
                ราคาต่อหน่วย
              </PDFTableCell>
              <PDFTableCell head width={83} textAlign="right">
                จำนวนเงิน
              </PDFTableCell>
            </PDFTableRow>
            {/* Content Table */}
            {products.map((product: SaleOrderProduct, index: number) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCell width={72}>{product.code}</PDFTableCell>
                <PDFTableCell width={212}>{product.nameLocal}</PDFTableCell>
                <PDFTableCell width={64} textAlign="right">
                  {numeral(product.amount).format('0,0')} {product.unitLocal}
                </PDFTableCell>
                <PDFTableCell width={80} textAlign="right">
                  {numeral(product.price).format('0,0.00')}
                </PDFTableCell>
                <PDFTableCell width={83} textAlign="right">
                  {numeral(product.amount * product.price).format('0,0.00')}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          {/* Footer Table */}
          <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(saleOrderProfile.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="หมายเหตุ" color="error">
                  {saleOrderProfile.remark}
                </PDFInfo>
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {priceBreakdowns
                .filter((price) => {
                  return price.amount > 0 || !['ส่วนลด', 'ค่าจัดส่ง'].includes(price.name)
                })
                .map((price) => (
                  <PDFInfo key={price.name} label={price.name} direction="row" dataWidth={160}>
                    {numeral(price.amount).format('0,0.00')}
                  </PDFInfo>
                ))}
            </PDFView>
          </PDFView>

          <PDFView bottomLine="lightGrey" style={{ padding: '8px 0px', fontSize: 12 }}>
            <Text>*** ซื้อครบ 499 บาท ส่งมอบสินค้าภายในกรุงเทพ และ ปริมณฑล ฟรี!!</Text>
            <Text>
              *** ต่างจังหวัด ลูกค้าเป็นผู้ออกค่าขนส่ง ทางบริษัทฯมีบริการส่งสินค้าตามบริษัทขนส่งที่ลูกค้ากำหนด
            </Text>
            <Text>
              *** กรุณาตรวจสอบรายการสินค้าก่อนการสั่งซื้อ ทางบริษัทฯไมมีนโยบายรับคืนสินค้าหากมีการเปิดใบสั่งซื้อมาแล้ว
            </Text>
          </PDFView>

          <PDFRow borders={['bottom']} borderColor="light" align="flex-start">
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เงื่อนไขการชำระเงิน">{saleOrderProfile.paymentMethod.name}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <View wrap={false}>
            <PDFColumn columnSpan={6}>
              <Image src={lineContact} style={{ width: 144, height: 44, marginTop: 8, marginBottom: 8 }} />
            </PDFColumn>

            <PDFRow>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView title="ลงนาม/ผู้ประทับตรา ผู้เสนอราคา" />
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView title="ลงนาม ผู้รับใบเสนอราคา" />
              </PDFColumn>
            </PDFRow>
          </View>

          <PDFPagination />
        </Page>
        {showedImagesPage && (
          <Page size="A4" style={styles.page}>
            <Text style={styles.title} fixed>
              ภาพสินค้าทั้งหมดจากใบสั่งขาย
            </Text>

            <PDFRow align="flex-start">
              {products
                .filter((p) => !!p.imageUrl)
                .map((product: SaleOrderProduct, index: number) => (
                  <PDFColumn columnSpan={4} key={index}>
                    <Image
                      style={styles.imageProductGallery}
                      src={{
                        uri: product.imageUrl!,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                        body: '',
                      }}
                    />
                    <PDFInfo label={product.code}>
                      <Text>{product.nameLocal}</Text>
                    </PDFInfo>
                  </PDFColumn>
                ))}
            </PDFRow>
          </Page>
        )}
      </Document>
    </SoraPDFViewer>
  )
}

export default SaleOrderPDF
