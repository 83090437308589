import { Box, CircularProgress, CircularProgressProps, Typography } from '@material-ui/core'
import theme from '../../theme'

export const CircularProgressWithLabel = (props: CircularProgressProps & { value: number }) => {
  const themeColor =
    props.value < 50
      ? theme.palette.primary.main
      : props.value < 80
      ? theme.palette.warning.main
      : theme.palette.success.main
  return (
    <Box position="relative" display="inline-flex">
      <CircularProgress style={{ color: themeColor, width: 36, height: 36 }} variant="determinate" {...props} />
      <Box
        top={0}
        left={0}
        bottom={0}
        right={0}
        position="absolute"
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="caption" component="div" color="textSecondary">{`${props.value}%`}</Typography>
      </Box>
    </Box>
  )
}
