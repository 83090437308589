import { useEffect, useState } from 'react'
import { getUserDataAPI } from '../../api/smartbestbuys-api/data/data-apis'
import { UserData } from '../../api/smartbestbuys-api/data/data-types'
import { getUserDataFromLocalStorage, setUserDataToLocalStorage } from '../../functions'
import { add } from 'date-fns'

export const useUserData = (): UserData[] => {
  const [value, setValue] = useState<UserData[]>([])

  useEffect(() => {
    ;(async () => {
      const usersStorage = getUserDataFromLocalStorage()

      if (!usersStorage || new Date().getTime() > usersStorage.expiredTimes) {
        const response = await getUserDataAPI()
        if (response?.status === 200) {
          const expiredTimes = add(new Date(), {
            hours: 4,
          }).getTime()

          setValue(response?.data)
          setUserDataToLocalStorage(response?.data, expiredTimes)
        }
      } else {
        setValue(usersStorage.users)
      }
    })()
  }, [])

  return value
}
