import { CustomerAddressCreationRequest, IPurchaseOrder, ISaleOrder, IVendor, IVendorContact } from '..'
import { ReceiveProductAction } from '../../../enums'
import { IPurchaseOrderTableData_SearchParams } from '../../../hooks/usePurchaseOrderTableData'
import { ITableData } from '../../../types/pagination'
import { ICustomerProfile_Address } from '../customer/types'
import {
  AttachmentUpdateForUpload,
  BaseData,
  DateTimeFilterParams,
  PurchaseProduct,
  PurchaseProductUpsertData,
  PurchaseRequisitionInfo,
  TableDataQueryParams,
} from '../types'

export interface VendorContactInfo {
  id: number
  name: string
  phoneNumber: string
}

export interface CustomerAddressWithConsignee extends ICustomerProfile_Address {
  consigneeName: string
  consigneePhoneNumber: string
}

// types for profile api

export interface PurchaseOrderProfile {
  id: number
  code: string
  purchaseRequisitionInfo: PurchaseOrderProfile_PurchaseRequsitionInfo
  productTotal: number
  discountAmount: number
  withholdingTaxPercentage?: number
  vatIncluded: boolean
  shippingByVendor: boolean
  total: number
  vendorShippingDate?: Date
  vendorQuotationCode?: string
  remark?: string
  depositAmount?: number
  paymentCondition: BaseData
  paymentMethod: BaseData
  currency: BaseData
  status: BaseData
  paymentStatus: BaseData
  cancellable: boolean
  recCreatedAt: Date
  recCreatedBy: string
  latestVerifiedAt?: Date
  latestVerifiedBy?: string
  recModifiedAt?: Date
  recModifiedBy?: string

  saleOrderInfo?: Pick<ISaleOrder, 'id' | 'code'>
  vendorInfo: PurchaseOrderProfile_VendorInfo
  customerShippingAddress?: CustomerAddressWithConsignee
  products: PurchaseOrderProfile_Product[]
  attachments?: PurchaseOrderProfile_Attachment[]
  activities: PurchaseOrderProfile_Activity[]
  payments?: PurchaseOrderProfile_Payment[]
  tasks?: PurchaseOrderProfile_Task[]
  verificationList?: string[]
  addtionalData?: PurchaseOrderProfile_AdditionalData
  documents?: PurchaseOrderProfile_Document[]
}

export interface PurchaseOrderProfile_PurchaseRequsitionInfo {
  id: number
  status: BaseData
  code: string
  total: number
  sumPurchaseOrderTotal: number
}

export interface PurchaseOrderProfile_VendorInfo {
  id: number
  profileImageUrl?: string
  code: string
  name: string
  referenceNumber: string
  averageRating?: number
  phoneNumber: string
  email?: string
  websiteUrl?: string
  scopeWork?: string
  remark?: string
  creditPeriodDay?: number

  purchasedAmount: number
  currencyInfo: BaseData
  contactInfo?: VendorContactInfo
  country?: string
}

export interface PurchaseOrderProfile_Product {
  id: number // productId
  imageUrl?: string
  name: string
  code: string
  nameEn?: string
  unitEn?: string
  amount: number
  price: number
  stockAmount: number
  type: BaseData
  receivedAmount: number
  unit?: string
  salePrice?: number
}

export interface PurchaseOrderProfile_Attachment {
  id: number
  purchaseOrderId: number
  attachmentUrl: string
}

export interface PurchaseOrderProfile_Activity {
  id: number
  userId: number
  purchaseOrderId: number
  actionId: number
  actionName: string
  actionMessageId?: number
  actionMessage?: string
  remark?: string
  recCreatedAt: Date
  recCreatedBy: string
}

export interface PurchaseOrderProfile_Payment {
  id: number
  code: string
  paymentCode?: string
  status: BaseData
  total: number
}

export interface PurchaseOrderProfile_Task {
  id: number
  code: string
  status: BaseData
  dueDate?: Date
  recCreatedAt: Date
}

export interface PurchaseOrderProfile_AdditionalData {
  activityRemark?: string
}

export type PurchaseOrderProfile_Document = Omit<PurchaseOrderDocument, 'recCreatedAt' | 'recCreatedBy'>
export interface PurchaseOrderDocument {
  id: number
  type: BaseData
  referenceNumber: string
  attachmentUrl: string
  referenceDate: Date
  total: number
  recCreatedAt: Date
  recCreatedBy: string
}

// for fetch purchase order api endpoint
export interface FetchPurchaseOrder {
  id: number
  code: string
  status: BaseData
  paymentStatus: BaseData
  vendorShippingDate?: Date
  vendor: BaseData
  total: number
  remark: string
  recCreatedAt: Date
  recCreatedBy: string
  latestVerifiedBy?: string
  latestVerifiedAt?: Date
  isPurchaseRequisitionCanceled: boolean
  requireManagerToCancel: boolean
  isShippingDateSet: boolean

  documentAmount?: number
  documentTotal?: number

  purchaseRequisitionInfo: FetchPurchaseOrder_PurchaseRequisition
  saleOrderInfo?: FetchPurchaseOrder_SaleOrder
}

export interface FetchPurchaseOrder_PurchaseRequisition {
  id: number
  code: string
  dueDate: Date
  status: BaseData
}

export interface FetchPurchaseOrder_SaleOrder {
  id: number
  code: string
  dueDate: Date
}

// purchase order create data API
export interface PurchaseOrderCreateData {
  purchaseRequisitionInfo: PurchaseRequisitionInfo
  products: PurchaseProductUpsertData[]
  customerShippingAddresses?: ICustomerProfile_Address[]
  vendors?: PurchaseOrderUpsertData_VendorInfo[]
  relatedPRProducts?: PurchaseOrderUpsertData_RelatedPRProduct[]
}

export interface PurchaseOrderUpsertData_RelatedPRProduct {
  id: number // productId
  imageUrl?: string
  code: string
  name: string
  amount: number
  price: number
  purchaseOrderCode: string
}

export interface PurchaseOrderUpsertData_VendorContactInfo {
  id: number
  name: string
  phoneNumber: string
}

export interface PurchaseOrderUpsertData_VendorInfo {
  id: number
  profileImageUrl?: string
  code: string
  name: string
  referenceNumber: string
  averageRating?: number
  phoneNumber: string
  email?: string
  websiteUrl?: string
  scopeWork?: string
  remark?: string
  currency: BaseData
  contactInfo?: VendorContactInfo

  country?: string
  refPaymentMethod?: BaseData
  creditPeriodDay?: number
  isVatVendor?: boolean
}

// purchase product purchase history API
export interface PurchaseProductPurchaseHistory {
  id: number
  code: string
  status: BaseData
  amount: number
  price: number
  vendorShippingDate?: Date
  vendor: BaseData
  receivedAmount: number
  recCreatedAt: Date
}

// purchase order create API
export interface PurchaseOrderCreateRequest {
  vendorId: number
  currencyId: number
  purchaseRequisitionId: number
  paymentConditionId: number
  paymentMethodId: number
  typeId: number
  saleOrderId?: number
  vendorContactId?: number
  vendorShippingDate?: string
  vendorQuotationCode?: string
  withholdingTaxPercentage?: number
  vatIncluded: boolean
  shippingByVendor: boolean
  depositAmount?: number
  total: number
  productTotal: number
  discountAmount?: number
  remark?: string
  exchangeRate: number
  shippingAddressInfo?: PurchaseOrderCreateRequest_ShippingAddressInfo
  products: PurchaseOrderCreateRequest_Product[]
  attachments?: PurchaseOrderCreateRequest_Attachment[]
}

// purchase order create data API
export interface PurchaseOrderCreateRequest_ShippingAddressInfo extends Partial<CustomerAddressCreationRequest> {
  consigneeName: string
  consigneePhoneNumber: string
  customerId?: number
  customerShippingAddressId?: number
}

export interface PurchaseOrderCreateRequest_Attachment {
  base64: string
}

export interface PurchaseOrderCreateRequest_Product {
  id: number
  amount: number
  price: number
  base64?: string
  imageUrl?: string
  code?: string
  name: string
  unit?: string
  nameEn?: string
  unitEn?: string
  typeId: number
}

export interface UpdatePurchaseOrderStatus {
  id: number
  status: BaseData
}

export interface PurchaseOrderAppointShippingDateRequest {
  vendorShippingDate: string
  remark?: string
}

export interface UpdatedPurchaseOrderShippingDate {
  id: number
  vendorShippingDate: Date
  status: BaseData
}

//upload document api
export interface UploadPurchaseOrderDocumentRequestBody {
  documentTypeId: number
  base64: string
  referenceNumber: string
  total: number
  referenceDate: string
}

//delete document api
export interface DeletePurchaseOrderDocumentURLParams {
  id: number
  documentId: number
}

export interface DeletedPurchaseOrderDocumentResponse {
  id: number
}

//get data update purchase order
export interface PurchaseOrderUpdateData extends PurchaseOrderUpsertData {
  purchaseOrderInfo: PurchaseOrderInfo
}

export interface PurchaseOrderUpsertData {
  purchaseRequisitionInfo: PurchaseRequisitionInfo
  products: PurchaseProductUpsertData[]
  customerShippingAddresses?: ICustomerProfile_Address[]
  vendors?: PurchaseOrderUpsertData_VendorInfo[]
  relatedPRProducts?: PurchaseOrderUpsertData_RelatedPRProduct[]
}

export interface PurchaseOrderInfo {
  id: number
  code: string
  purchaseRequisitionId: number
  purchaseRequisitionCode: string
  discountAmount?: number
  withholdingTaxPercentage?: number
  vatIncluded: boolean
  shippingByVendor: boolean
  total: number
  vendorShippingDate?: Date
  vendorQuotationCode?: string
  remark?: string
  depositAmount?: number
  paymentCondition: BaseData
  paymentMethod: BaseData
  currency: BaseData
  status: BaseData
  paymentStatus: BaseData
  cancellable: boolean
  recCreatedAt: Date
  recCreatedBy: string
  latestVerifiedAt?: Date
  latestVerifiedBy?: string
  recModifiedAt?: Date
  recModifiedBy?: string
  vendorInfo: PurchaseOrderInfo_VendorInfo
  customerShippingAddress?: CustomerAddressWithConsignee

  products: PurchaseOrderProduct[]
  attachments?: PurchaseOrderAttachment[]
}

export interface PurchaseOrderInfo_VendorInfo {
  id: number
  profileImageUrl?: string
  code: string
  name: string
  referenceNumber: string
  averageRating?: number
  phoneNumber: string
  email?: string
  websiteUrl?: string
  scopeWork?: string
  remark?: string
  currency: BaseData
  contact: VendorContactInfo
  creditPeriodDay?: number

  refPaymentMethod?: BaseData
  country?: string
}

export interface PurchaseOrderProduct {
  id: number // => productId
  imageUrl?: string
  name: string
  nameEn?: string
  code: string
  unit?: string
  unitEn?: string
  amount: number
  price?: number
  type: BaseData
  stockAmount?: number
  receivedAmount: number
}

export interface PurchaseOrderAttachment {
  id: number
  purchaseOrderId: number
  attachmentUrl: string
}

// update purchase order
export interface PurchaseOrderUpdateRequest {
  paymentConditionId: number
  paymentMethodId: number
  vendorQuotationCode?: string
  withholdingTaxPercentage?: number
  vatIncluded: boolean
  shippingByVendor: boolean
  depositAmount?: number
  total: number
  productTotal: number
  discountAmount?: number
  remark?: string
  exchangeRate: number
  shippingAddressInfo?: PurchaseOrderUpdateRequest_ShippingAddressInfo
  products: PurchaseOrderUpdateRequest_Product[]
  attachments?: AttachmentUpdateForUpload[]
}

export interface PurchaseOrderUpdateRequest_ShippingAddressInfo extends CustomerAddressCreationRequest {
  consigneeName: string
  consigneePhoneNumber: string
  customerId?: number
  customerShippingAddressId?: number
}

export interface PurchaseOrderUpdateRequest_Product {
  id?: number
  amount: number
  price?: number
  productId?: number
  base64?: string
  imageUrl?: string
  code?: string
  name: string
  unit?: string
  typeId: number
  nameEn?: string
  unitEn?: string
}

// purchase order pdf

export type PurchaseOrderPDFInfo_Product = PurchaseProduct

export interface PurchaseOrderPDFInfo {
  id: number
  code: string
  refSaleOrderCode?: string
  productTotal: number
  discountAmount: number
  withholdingTaxPercentage?: number
  vatIncluded: boolean
  shippingByVendor: boolean
  total: number
  vendorShippingDate?: Date
  vendorQuotationCode?: string
  remark?: string
  depositAmount?: number
  paymentCondition: BaseData
  paymentMethod: BaseData
  currency: BaseData
  status: BaseData
  paymentStatus: BaseData
  cancellable: boolean
  recCreatedAt: Date
  recCreatedBy: string
  latestVerifiedAt?: Date
  latestVerifiedBy?: string
  recModifiedAt?: Date
  recModifiedBy?: string

  vendorInfo: PurchaseOrderPDFInfo_VendorInfo
  customerShippingAddress?: CustomerAddressWithConsignee
  products: PurchaseOrderPDFInfo_Product[]
}

export interface PurchaseOrderPDFInfo_VendorInfo {
  id: number
  name: string
  referenceNumber: string
  phoneNumber: string
  currency: BaseData
  contactInfo?: VendorContactInfo
  address: ICustomerProfile_Address
}

// pending payment purchase order
export interface PendingPaymentPurchaseOrder {
  id: number
  code: string
  status: BaseData
  paymentStatus: BaseData
  vendorShippingDate?: Date
  total: number
  recCreatedBy: string
  recCreatedAt: Date
  readyToPay?: boolean
  paymentDueDate?: Date
  vendorInfo: PendingPaymentPurchaseOrder_Vendor
  documents: PendingPaymentPurchaseOrder_Document[]
  paymentTransactions: PendingPaymentPurchaseOrder_PaymentRequisition[]
}

export interface PendingPaymentPurchaseOrder_Vendor {
  id: number
  name: string
  creditPeriodDay?: number
}

export interface PendingPaymentPurchaseOrder_Document {
  id: number
  purchaseOrderId: number
  documentType: BaseData
  total: number
  referenceDate: Date
  paymentDueDate?: Date
}

export interface PendingPaymentPurchaseOrder_PaymentRequisition {
  id: number
  purchaseOrderId?: number
  total: number
}

export type GetPurchaseOrderTableDataQueryParams = TableDataQueryParams & GetPurchaseOrderTableDataRequestQueryParams

export type GetPurchaseOrderTableDataRequestQueryParams = {
  purchaseOrderStatusIds?: string
  paymentStatusIds?: string
  isPRCancelled?: string
  isNoVendorAppointment?: string
  isNoDocument?: string
  customerAppointmentDate?: string
  vendorShippingDate?: string
  vendorName?: string
  code?: string
  saleOrderCode?: string
  createdBy?: string
}

export interface IPurchaseOrderTableData_Data {
  id: number
  code: string
  status: BaseData
  paymentStatus: BaseData
  vendorShippingDate?: Date
  vendor: BaseData
  total: number
  remark: string
  recCreatedAt: Date
  recCreatedBy: string
  latestVerifiedBy?: string
  latestVerifiedAt?: Date
  isPurchaseRequisitionCanceled: boolean
  requireManagerToCancel: boolean
  isShippingDateSet: boolean

  documentAmount?: number
  documentTotal?: number

  purchaseRequisition?: {
    id: number
    code: string
    dueDate?: Date
    status: BaseData
  }
  saleOrder?: {
    id: number
    code: string
    dueDate?: Date
  }
}

export type IPurchaseOrderTableData = ITableData<IPurchaseOrderTableData_Data, IPurchaseOrderTableData_SearchParams>

export interface PurchaseOrderTakeNoteRequestBody {
  remark: string
}

export interface PurchaseOrderReceivedProductRequestBody {
  nextVendorShippingDate?: string
  remark?: string
  exchangeRate: number
  results: PurchaseOrderReceivedProductRequestBody_ReceivedProduct[]
}

export interface PurchaseOrderReceivedProductRequestBody_ReceivedProduct {
  id: number
  imageUrl?: string
  name: string
  code: string
  unit?: string
  action: ReceiveProductAction
  receivedAmount: number
  expectedAmount: number
}

export interface GetPurchaseOrderPendingReceiveProductQueryParams {
  productTypeId?: string
}

export interface IGetPurchaseOrderPendingReceiveProduct {
  id: number
  code: string
  vendorShippingDate?: Date
  recCreatedAt: Date
  vendor: Pick<IVendor, 'id' | 'code' | 'name'>
}

export interface IGetPOPendingReceiveProductById {
  id: number
  code: string
  status: BaseData
  vendorShippingDate?: Date
  currency: BaseData
  saleOrderInfo?: Pick<ISaleOrder, 'id' | 'code'>
  vendorInfo: Pick<
    IVendor,
    | 'id'
    | 'profileImageUrl'
    | 'code'
    | 'name'
    | 'referenceNumber'
    | 'averageRating'
    | 'phoneNumber'
    | 'email'
    | 'websiteUrl'
    | 'scopeWork'
    | 'remark'
    | 'creditPeriodDay'
    | 'currency'
  > & {
    contactInfo?: VendorContactInfo
  }
  products: IGetPOPendingReceiveProductById_Product[]
}

export interface IGetPOPendingReceiveProductById_Product {
  id: number // product_id
  name: string
  code: string
  unit?: string
  type: BaseData
  amount: number
  receivedAmount: number
  productImageUrl?: string
}

export type IPOActivityReceiveProductTableData = ITableData<
  POActivityReceiveProductTable.Data,
  POActivityReceiveProductTable.SearchParams,
  POActivityReceiveProductTable.Summary
>

export namespace POActivityReceiveProductTable {
  export interface Data {
    id: number
    state?: POActivityReceiveProductState
    remark?: string
    recCreatedAt: Date
    recCreatedBy: string
    purchaseOrder: Pick<IPurchaseOrder, 'id' | 'code'>
    vendor: Pick<IVendor, 'id' | 'code' | 'name'>
    vendorContact?: Pick<IVendorContact, 'id' | 'name'>
    saleOrder?: Pick<ISaleOrder, 'id' | 'code'>
  }

  export interface SearchParams {
    purchaseOrderCode?: string
    startDateTime: Date
    endDateTime: Date
  }

  export interface Summary {}
}

export interface POActivityReceiveProductState {
  nextVendorShippingDate?: string
  remark?: string
  exchangeRate: number
  results: ReceivedProduct[]
}

export interface ReceivedProduct {
  id: number
  imageUrl?: string
  name: string
  code: string
  unit?: string
  action: ReceiveProductAction
  receivedAmount: number
  expectedAmount: number
}

export type GetPOActivityTableDataSearchParams = DateTimeFilterParams & {
  purchaseOrderCode?: string
}

export type GetPOActivityTableDataQueryParams = TableDataQueryParams &
  DateTimeFilterParams & {
    purchaseOrderCode?: string
  }
