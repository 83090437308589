import { FC } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font } from '@react-pdf/renderer'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
  PDFTableCellView,
} from '../../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../../assets/images/new-logo.png'
import { useDocument } from '../../../../../hooks'
import numeral from 'numeral'
import { ThaiBaht } from 'thai-baht-text-ts'
import { ReceiptState } from '../../../../../api/smartbestbuys-api'
import { dateFormat, getAddressText } from '../../../../../functions'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 52,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 272,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 12,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 12,
    marginBottom: 4,
    marginTop: 4,
  },
})

const formatDecimal = (number: number) => numeral(number).format('0,0.00')

const ReceiptPDFPage: FC<RouteComponentProps<any>> = (props) => {
  const { id } = props.match.params
  const receipt = useDocument<ReceiptState>(id)

  if (receipt) {
    document.title = `ใบเสร็จรับเงิน ${receipt.code}`

    const { billingAddress } = receipt

    return (
      <SoraPDFViewer>
        <Document>
          <Page size="A4" style={styles.page}>
            {/* Header */}
            <PDFRow style={styles.spaceBetween} fixed>
              <PDFColumn columnSpan={2}>
                <Image style={styles.logo} src={smartBestBuysLogo} />
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <PDFView style={styles.contactPointBox}>
                  <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                  <PDFView style={styles.textMini}>
                    <Text>www.smartbestbuys.com</Text>
                    <Text>
                      <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>
                      0105548090347
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ที่อยู่: </Text>519/174-176 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ
                      กรุงเทพฯ 10140
                    </Text>
                    <PDFView style={styles.spaceBetween}>
                      <Text>
                        <Text style={styles.bold}>โทร: </Text>02-1147006
                      </Text>
                      <Text>
                        <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                      </Text>
                    </PDFView>
                    <PDFView style={styles.spaceBetween}>
                      <Text>
                        <Text style={styles.bold}>อีเมล: </Text>
                        sale@smartbestbuys.com
                      </Text>
                      <Text>
                        <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                      </Text>
                    </PDFView>
                  </PDFView>
                </PDFView>
              </PDFColumn>
              <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
                <PDFInfo label="เลขที่" direction="row" dataWidth={88} noMargin>
                  {receipt.code}
                </PDFInfo>
                <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                  {dateFormat(receipt.recCreatedAt)}
                </PDFInfo>
              </PDFColumn>
            </PDFRow>
            {/* Title */}
            <Text style={styles.topic} fixed>
              ใบเสร็จรับเงิน
            </Text>
            {/* SubHeader */}
            <PDFRow borderColor="light">
              <PDFColumn columnSpan={8}>
                <PDFInfo label="ชื่อลูกค้า">
                  {(billingAddress?.overrideDocumentData?.customerName || receipt.customerInfo.name) + '     '}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={4}>
                <PDFInfo label="เลขประจำตัวผู้เสียภาษี">
                  {billingAddress?.overrideDocumentData?.taxNumber || receipt.customerInfo.referenceNumber}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={12}>
                <PDFInfo label="ที่อยู่">
                  {!!billingAddress
                    ? billingAddress.overrideDocumentData?.address || getAddressText(billingAddress)
                    : receipt.customerInfo.address}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={4}>
                <PDFInfo label="โทรศัพท์">{receipt.customerInfo.phoneNumber}</PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={8}>
                <PDFInfo label="โทรสาร">{billingAddress?.faxNumber || '-'}</PDFInfo>
              </PDFColumn>
            </PDFRow>

            <PDFTable>
              {/* Head Table */}
              <PDFTableRow bottomLine topLine>
                <PDFTableCell head width={44} textAlign="center">
                  ลำดับ
                </PDFTableCell>
                <PDFTableCell head width={208}>
                  รายละเอียดอ้างอิงใบกำกับภาษี
                </PDFTableCell>
                <PDFTableCell head width={75} textAlign="center">
                  วันที่เอกสาร
                </PDFTableCell>
                <PDFTableCell head width={80} textAlign="center">
                  วันที่ครบกำหนด
                </PDFTableCell>
                <PDFTableCell head width={80} textAlign="right">
                  จำนวนเงิน
                </PDFTableCell>
                <PDFTableCell head width={80} textAlign="right">
                  ยอดชำระ
                </PDFTableCell>
              </PDFTableRow>
              {/* Content Table */}
              {receipt.state?.items.map((item, index) => (
                <PDFTableRow bottomLine>
                  <PDFTableCell width={44} textAlign="center">
                    {index + 1}
                  </PDFTableCell>
                  <PDFTableCellView width={208}>
                    <Text>{item.name}</Text>
                  </PDFTableCellView>
                  {/* TODO: add createAt date + expired date */}
                  <PDFTableCell width={75} textAlign="center">
                    -
                  </PDFTableCell>
                  <PDFTableCell width={80} textAlign="center">
                    -
                  </PDFTableCell>
                  <PDFTableCell width={80} textAlign="right">
                    {formatDecimal(item.total || 0)}
                  </PDFTableCell>
                  <PDFTableCell width={80} textAlign="right">
                    {formatDecimal(item.total || 0)}
                  </PDFTableCell>
                </PDFTableRow>
              ))}
            </PDFTable>

            {/* Footer Table */}
            <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
              <PDFView>
                <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                  <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(receipt.total || 0)}</PDFInfo>
                </PDFView>
                <PDFView style={styles.tableFooter}>
                  <PDFInfo label="หมายเหตุ">{receipt.state?.remark || '-'}</PDFInfo>
                </PDFView>
              </PDFView>
              <PDFView style={styles.summaryBox}>
                {receipt.state?.priceBreakdowns.map((item) => (
                  <PDFInfo key={item.label} label={item.label} direction="row" dataWidth={188}>
                    {numeral(item.amount).format('0,0.00')}
                  </PDFInfo>
                ))}
              </PDFView>
            </PDFView>

            <PDFView style={{ padding: '8px 0px' }} wrap={false}>
              <PDFRow align="flex-start">
                {/* Title */}
                <PDFColumn columnSpan={12} style={{ marginBottom: 8 }}>
                  <Text style={{ fontFamily: 'THSarabunNewBold', marginBottom: 8 }}>
                    ใบเสร็จรับเงินจะสมบูรณ์ต่อเมื่อได้รับเงินและเงินผ่านบัญชีเข้าธนาคารแล้ว
                  </Text>
                </PDFColumn>
                {/* Row 1 */}
                <PDFColumn columnSpan={1} style={{ marginBottom: 8 }}>
                  <PDFBox />
                </PDFColumn>
                <PDFColumn columnSpan={1}>
                  <Text>เงินสด</Text>
                </PDFColumn>
                <PDFColumn columnSpan={10}>
                  <Text>จำนวนเงิน ...........................บาท</Text>
                </PDFColumn>
                {/* Row 2 */}
                <PDFColumn columnSpan={1} style={{ marginBottom: 8 }}>
                  <PDFBox />
                </PDFColumn>
                <PDFColumn columnSpan={1}>
                  <Text>เช็ค</Text>
                </PDFColumn>
                <PDFColumn columnSpan={3}>
                  <Text>เลขที่เช็ค ..............................</Text>
                </PDFColumn>
                <PDFColumn columnSpan={3}>
                  <Text>ธนาคาร ......................................</Text>
                </PDFColumn>
                <PDFColumn columnSpan={4}>
                  <Text>สาขา ........................................................</Text>
                </PDFColumn>
                {/* Row 3 */}
                <PDFColumn columnSpan={1} style={{ marginBottom: 8 }} />
                <PDFColumn columnSpan={1} />
                <PDFColumn columnSpan={3}>
                  <Text>วันที่เช็ค ......../......../............</Text>
                </PDFColumn>
                <PDFColumn columnSpan={7}>
                  <Text>จำนวนเงิน ...........................บาท</Text>
                </PDFColumn>
                {/* Row 4 */}
                <PDFColumn columnSpan={1} style={{ marginBottom: 8 }} />
                <PDFColumn columnSpan={1} />
                <PDFColumn columnSpan={3}>
                  <Text>เลขที่เช็ค ..............................</Text>
                </PDFColumn>
                <PDFColumn columnSpan={3}>
                  <Text>ธนาคาร ......................................</Text>
                </PDFColumn>
                <PDFColumn columnSpan={4}>
                  <Text>สาขา ........................................................</Text>
                </PDFColumn>
                {/* Row 5 */}
                <PDFColumn columnSpan={1} style={{ marginBottom: 8 }} />
                <PDFColumn columnSpan={1} />
                <PDFColumn columnSpan={3}>
                  <Text>วันที่เช็ค ......../......../............</Text>
                </PDFColumn>
                <PDFColumn columnSpan={7}>
                  <Text>จำนวนเงิน ...........................บาท</Text>
                </PDFColumn>
                {/* Row 6 */}
                <PDFColumn columnSpan={1} style={{ marginBottom: 8 }}>
                  <PDFBox />
                </PDFColumn>
                <PDFColumn columnSpan={1}>
                  <Text>โอน</Text>
                </PDFColumn>
                <PDFColumn columnSpan={3}>
                  <Text>วันที่โอน ......../......../............</Text>
                </PDFColumn>
                <PDFColumn columnSpan={7}>
                  <Text>จำนวนเงิน ...........................บาท</Text>
                </PDFColumn>
                {/* Row 7 */}
                <PDFColumn columnSpan={1} style={{ marginBottom: 8 }} />
                <PDFColumn columnSpan={1} />
                <PDFColumn columnSpan={3}>
                  <Text>วันที่โอน ......../......../............</Text>
                </PDFColumn>
                <PDFColumn columnSpan={7}>
                  <Text>จำนวนเงิน ...........................บาท</Text>
                </PDFColumn>
              </PDFRow>
            </PDFView>

            <PDFRow wrap={false}>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView width={120} title="ผู้รับเงิน" titlePaddingBottom={32} />
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView
                  width={120}
                  title={`ในนามบริษัท สมาร์ทเบสท์บายส์จำกัด
            ลงนามผู้รับมอบอำนาจ`}
                  endorser={receipt.recCreatedBy}
                  date={receipt.recCreatedAt}
                />
              </PDFColumn>
            </PDFRow>

            <PDFPagination />
          </Page>
        </Document>
      </SoraPDFViewer>
    )
  } else {
    return <Loading />
  }
}

const PDFBox = () => (
  <PDFRow
    borders={['bottom', 'left', 'right', 'top']}
    style={{
      padding: 0,
      width: 12,
      height: 12,
    }}
  ></PDFRow>
)

export default ReceiptPDFPage
