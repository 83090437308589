import { useEffect, useState } from 'react'
import { getAddressDataAPI } from '../../api/smartbestbuys-api/data/data-apis'
import { AddressData } from '../../api/smartbestbuys-api/data/data-types'
import { getAddressDataFromLocalStorage, setAdressDataToLocalStorage } from '../../functions'
import { add } from 'date-fns'

export const useAddressData = (): AddressData | undefined => {
  const [value, setValue] = useState<AddressData | undefined>(undefined)

  useEffect(() => {
    ;(async () => {
      const addressStorage = getAddressDataFromLocalStorage()
      if (!addressStorage || new Date().getTime() > addressStorage.expiredTimes) {
        const response = await getAddressDataAPI()
        const addressData = response?.data

        if (addressData) {
          const expiredTimes = add(new Date(), {
            hours: 4,
          }).getTime()

          setValue(addressData)
          setAdressDataToLocalStorage(addressData, expiredTimes)
        }
      } else {
        setValue(addressStorage.address)
      }
    })()
  }, [])

  return value
}
