import { useState, useEffect } from 'react'
import { getPurchaseProductHistory, PurchaseProductPurchaseHistory } from '../api/smartbestbuys-api'

export const usePurchaseProductHistory = (productId: number): PurchaseProductPurchaseHistory[] | undefined => {
  const [data, setData] = useState<PurchaseProductPurchaseHistory[] | undefined>()

  const _getPurchaseProductHistory = async (productId: number) => {
    const response = await getPurchaseProductHistory(productId)
    setData(response?.data)
  }

  useEffect(() => {
    if (productId) {
      _getPurchaseProductHistory(productId)
    } else {
      setData(undefined)
    }
  }, [productId])

  return data
}
