/* eslint-disable react-hooks/exhaustive-deps */
import {
  GetQuotationTableDataRequestSearchParam,
  IQuotationTableData,
  IQuotationTableData_Data,
  getQuotationTableDataAPI,
} from '../../api/smartbestbuys-api'
import { EvaluaionType } from '../../enums'
import { useTableData } from '../useTablaData'

export interface QuotationTableDataSearchParam {
  code?: string
  customerName?: string
  phoneNumber?: string
  statusIds?: number[]
  assigneeUserId?: number
  startDate?: Date
  endDate?: Date
  dueDate?: Date
  isNoSaleOrder?: boolean
  isNeedToFollow?: boolean
  requestChanged?: boolean
  evaluationType?: string
}

const _buildRequestParams = (_s: QuotationTableDataSearchParam): GetQuotationTableDataRequestSearchParam => {
  const {
    code,
    customerName,
    phoneNumber,
    statusIds,
    isNoSaleOrder,
    isNeedToFollow,
    requestChanged,
    assigneeUserId,
    startDate,
    endDate,
    dueDate,
    evaluationType,
  } = _s

  let evaluationScrollFrom = undefined
  let evaluationScrollTo = undefined

  if (evaluationType === EvaluaionType.Default) {
    evaluationScrollFrom = undefined
    evaluationScrollTo = undefined
  } else if (evaluationType === EvaluaionType.NoEvaluation) {
    evaluationScrollFrom = '-1'
    evaluationScrollTo = '-1'
  } else if (evaluationType === EvaluaionType.Low) {
    evaluationScrollFrom = '0'
    evaluationScrollTo = '20'
  } else if (evaluationType === EvaluaionType.Middle) {
    evaluationScrollFrom = '21'
    evaluationScrollTo = '40'
  } else if (evaluationType === EvaluaionType.Medium) {
    evaluationScrollFrom = '41'
    evaluationScrollTo = '60'
  } else if (evaluationType === EvaluaionType.Interested) {
    evaluationScrollFrom = '61'
    evaluationScrollTo = '80'
  } else if (evaluationType === EvaluaionType.HighReadiness) {
    evaluationScrollFrom = '81'
    evaluationScrollTo = '100'
  }
  return {
    code: code ? code : '',
    customerName: customerName ? customerName : '',
    phoneNumber: phoneNumber ? phoneNumber : '',
    statusIds: statusIds ? statusIds?.join(',') : '',
    assigneeUserId: assigneeUserId ? assigneeUserId.toString() : '',
    isNoSaleOrder: isNoSaleOrder?.toString(),
    isNeedToFollow: isNeedToFollow?.toString(),
    requestChanged: requestChanged ? requestChanged.toString() : undefined,
    startDate: startDate ? startDate.toISOString() : '',
    endDate: endDate ? endDate.toISOString() : '',
    dueDate: dueDate ? dueDate.toISOString() : '',
    evaluationScrollFrom,
    evaluationScrollTo,
  }
}

export const useQuotationTableData = () => {
  return useTableData<
    IQuotationTableData_Data,
    IQuotationTableData['summary'],
    QuotationTableDataSearchParam,
    GetQuotationTableDataRequestSearchParam
  >(
    0,
    10,
    {
      code: '',
      customerName: '',
      phoneNumber: '',
      statusIds: [5] as number[],
      assigneeUserId: undefined,
      isNoSaleOrder: undefined,
      isNeedToFollow: undefined,
      requestChanged: undefined,
      startDate: undefined,
      endDate: undefined,
      dueDate: undefined,
      evaluationType: undefined,
    },
    getQuotationTableDataAPI,
    _buildRequestParams,
  )
}
