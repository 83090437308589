import React, { useContext, useState } from 'react'
import { Avatar, Paper, TableCell, TableRow, Typography } from '@material-ui/core'
import { FlexBox, SubTopic, Table, TColumn } from '../../../custom-components'
import { Photo } from '@material-ui/icons'
import { PurchaseOrderProfilePageContext } from './PurchaseOrderProfilePage'
import { PurchaseOrderProfile_Product } from '../../../api/smartbestbuys-api/purchase-order/types'
import numeral from 'numeral'

import './PurchaseOrderProfileProductsTable.less'
import { formatNumber } from '../../../functions/number-utils'
import { getUserPermissionInfo } from '../../../utils/permission'
import { PermissionEnum } from '../../../enums/PermissionEnum'
import PurchaseOrderReceiveProductDialog from './PurchaseOrderReceiveProductDialog'

interface Props {
  hideSalePrice?: boolean
}

const PurchaseOrderProfileProductsTable: React.FC<Props> = (props: Props) => {
  // props
  const { hideSalePrice } = props

  // state
  const [openReceivedProduct, setOpenReceivedProduct] = useState<boolean>(false)

  // context
  const { purchaseOrderProfile } = useContext(PurchaseOrderProfilePageContext)
  const { products, total, productTotal, discountAmount, vatIncluded, vendorInfo } = purchaseOrderProfile

  const vendorCountry = !!vendorInfo?.country && vendorInfo.country !== 'ไทย' ? vendorInfo.country : 'ไทย'

  // calculation
  const exactDiscountAmount = discountAmount ?? 0
  const sameTotal = productTotal === total
  const priceBreakdowns = [
    { label: 'รวมเงิน', value: sameTotal ? 0 : productTotal },
    { label: 'ส่วนลด', value: exactDiscountAmount },
    { label: 'ภาษีมูลค่าเพิ่ม 7 %', value: vatIncluded ? total - productTotal - exactDiscountAmount : 0 },
    { label: 'ยอดเงินสุทธิ', value: total },
  ]

  const salePriceColumns = hideSalePrice
    ? []
    : [
        {
          name: 'ราคาที่เราขายต่อหน่วย',
          render: ({ salePrice }) => (!!salePrice ? formatNumber(salePrice) : '-'),
          align: 'right',
        } as TColumn<PurchaseOrderProfile_Product>,
      ]

  // const ableToReceive = whitelistedStatusToPurchaseOrderReceiveProduct.includes(purchaseOrderProfile.status.id)

  // permission
  const pricingPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Pricing)

  // render
  return (
    <>
      <PurchaseOrderReceiveProductDialog
        open={openReceivedProduct}
        purchaseOrder={purchaseOrderProfile}
        onComplete={() => {
          window.location.reload()
        }}
        onClose={() => {
          setOpenReceivedProduct(false)
        }}
      />
      <FlexBox p={3} justifyContent="space-between" flexDirection="column" clone>
        <Paper>
          <div className="flex justify-between">
            <SubTopic>ข้อมูลสินค้า/บริการ</SubTopic>
            {/* <ConditionTooltip
              title={`ไม่สามารถรับสินค้าในสถานะ ${purchaseOrderProfile.status.name} ได้`}
              showTooltip={!ableToReceive}
            >
              <Button
                variant="contained"
                color="primary"
                disabled={!ableToReceive}
                classes={{ label: 'px-2 leading-none' }}
                onClick={() => setOpenReceivedProduct(true)}
              >
                รับสินค้าจากผู้ขาย
              </Button>
            </ConditionTooltip> */}
          </div>
          <div className="mb-4" />
          <Table<PurchaseOrderProfile_Product>
            color="primary.dark"
            columns={[
              ...([
                { name: 'ลำดับ', render: (_, index) => index + 1, align: 'center' },
                {
                  name: 'ชื่อสินค้า/บริการ',
                  dataIndex: 'name',
                  render: (product) => (
                    <div className="purchase-order-products-table__product-cell" key={'product-' + product.id}>
                      <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                        <Photo />
                      </Avatar>
                      <div className="purchase-order-products-table__product-cell__info">
                        <Typography color="textSecondary">{product.code}</Typography>
                        <Typography>
                          {vendorCountry === 'ไทย' ? product.name : product.nameEn || product.name}
                        </Typography>
                      </div>
                    </div>
                  ),
                },
              ] as TColumn<PurchaseOrderProfile_Product>[]),
              ...salePriceColumns,
              ...([
                { name: 'จำนวนที่ได้รับแล้ว', dataIndex: 'receivedAmount', numeric: true, format: '0,0' },
                { name: 'จำนวน', dataIndex: 'amount', numeric: true, format: '0,0' },
                {
                  name: 'ราคาต่อหน่วย',
                  dataIndex: 'price',
                  numeric: true,
                  format: '0,0.00',
                  hide: !pricingPermission.hasPermission,
                },
                {
                  name: 'ราคารวม',
                  render: (product) => product.amount * product.price,
                  numeric: true,
                  format: '0,0.00',
                  hide: !pricingPermission.hasPermission,
                },
              ] as TColumn<PurchaseOrderProfile_Product>[]),
            ]}
            data={products}
            postCells={
              <>
                {pricingPermission.hasPermission &&
                  priceBreakdowns
                    .filter((item) => item.value > 0)
                    .map((item) => (
                      <TableRow>
                        <TableCell colSpan={hideSalePrice ? 3 : 4} />
                        <TableCell align="right">{item.label}</TableCell>
                        <TableCell align="right">{numeral(item.value).format('0,0.00')}</TableCell>
                      </TableRow>
                    ))}
              </>
            }
            pagination={false}
            cleanSpace
          />
        </Paper>
      </FlexBox>
    </>
  )
}

export default PurchaseOrderProfileProductsTable
