import React, { FC } from 'react'
import { Document, Page, StyleSheet, Image, Text, Font, View } from '@react-pdf/renderer'
import numeral from 'numeral'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
  PDFTableCellView,
} from '../../../../shared-components'
import { RouteComponentProps } from 'react-router-dom'
import smartBestBuysLogo from '../../../../assets/images/logo_v2.png'
import { usePurchaseOrderPDFInfo } from '../../../../hooks'
import { dateFormatEnglish, getAddressText } from '../../../../functions'
import { PurchaseOrderPDFInfo_Product } from '../../../../api/smartbestbuys-api'
import converter from 'number-to-words'
import { getPurchasePaymentConditionNameById } from '../../../../types'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const color = {
  black: '#222021',
  darkGrey: '#6A6969',
  lightGrey: '#C5BEC0',
  white: 'white',
  primary: '#E83872',
  error: '#CF505F',
}

const styles = StyleSheet.create({
  page: {
    padding: 20,
    fontSize: 14,
    fontFamily: 'THSarabunNewBold',
    lineHeight: 1,
  },
  center: {
    textAlign: 'center',
  },
  spaceBetween: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bold: {
    fontFamily: 'THSarabunNewBold',
  },
  logo: {
    height: 78,
    width: 78,
  },
  textMini: {
    fontSize: 12,
  },
  primaryFontColor: {
    color: color.primary,
  },
  contactPointBox: {
    width: 265,
  },
  title: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
  },
  topic: {
    paddingBottom: 4,
    textAlign: 'center',
    fontSize: 20,
    fontFamily: 'THSarabunNewBold',
    width: '100%',
    borderBottomColor: color.lightGrey,
    borderBottomWidth: 1,
  },
  tableFooter: {
    paddingLeft: 8,
    marginTop: 4,
    width: 272,
  },
  rowAndSpaceBetween: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  summaryBox: {
    paddingLeft: 15,
    borderLeftColor: color.darkGrey,
    borderLeftWidth: 1,
    paddingRight: 8,
    marginBottom: 4,
    marginTop: 4,
  },
  imageTabelCell: {
    borderRadius: 4,
    marginRight: 8,
  },
  imageProductGallery: {
    height: 150,
    width: 'auto',
    paddingRight: 20,
  },
})

const PurchaseOrderPDFEnglishPage: FC<RouteComponentProps<any>> = (props) => {
  const { id: purchaseOrderId } = props.match.params
  const purchaseOrderPDFInfo = usePurchaseOrderPDFInfo(purchaseOrderId)
  if (!purchaseOrderPDFInfo) return <Loading />

  const { paymentCondition, products, vendorInfo, customerShippingAddress } = purchaseOrderPDFInfo

  document.title = `ใบสั่งซื้อ ${purchaseOrderPDFInfo.code}`

  // calculation
  const exactDiscountAmount = purchaseOrderPDFInfo.discountAmount ?? 0
  const priceBreakdowns = [
    { label: 'Gross total', value: purchaseOrderPDFInfo.productTotal },
    { label: 'Discount', value: exactDiscountAmount },
    { label: 'Sub total', value: purchaseOrderPDFInfo.productTotal - exactDiscountAmount },
    {
      label: 'Vat 7 %',
      value: purchaseOrderPDFInfo.vatIncluded
        ? purchaseOrderPDFInfo.total - purchaseOrderPDFInfo.productTotal - exactDiscountAmount
        : 0,
    },
    { label: 'Grand total ', value: purchaseOrderPDFInfo.total },
  ]

  const showedImagesPage = !!products.find((p) => !!p.imageUrl)

  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>Smart Bestbuys Co.,Ltd </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>Tax ID: </Text>0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>Address: </Text>519/174-176 soi Prachaautid 121 Thungkru Bangkok Thailand
                    10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>Tel: </Text>02-114-8050, 087-022-4003, 084-544-1710
                    </Text>
                    <Text>
                      <Text style={styles.bold}>Fax: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>Email: </Text>sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ID LINE: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="PO Number" direction="row" dataWidth={88} noMargin>
                {purchaseOrderPDFInfo.code}
              </PDFInfo>
              <PDFInfo label="Date" direction="row" dataWidth={88} noMargin>
                {dateFormatEnglish(purchaseOrderPDFInfo.recCreatedAt, 'dd/MM/yyyy')}
              </PDFInfo>
              {!!purchaseOrderPDFInfo.refSaleOrderCode && (
                <PDFInfo label="SO Reference" direction="row" dataWidth={88} noMargin>
                  {purchaseOrderPDFInfo.refSaleOrderCode}
                </PDFInfo>
              )}
              <PDFInfo label="Term Of Payment" direction="row" dataWidth={88} noMargin>
                {getPurchasePaymentConditionNameById(paymentCondition.id, true)}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            PURCHASE ORDER
          </Text>
          {/* SubHeader */}
          <PDFRow borderColor="light">
            <PDFColumn columnSpan={4}>
              <PDFInfo label="Supplier Name">{vendorInfo.name}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="Tax ID">{vendorInfo.referenceNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="Tel">{vendorInfo.phoneNumber}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="Address"> {getAddressText(vendorInfo.address)}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="Contact Name">{vendorInfo.contactInfo?.name}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="Contact Tel">{vendorInfo.contactInfo?.phoneNumber}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <PDFTable>
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                No
              </PDFTableCell>
              <PDFTableCell head width={284}>
                Description
              </PDFTableCell>
              <PDFTableCell head width={64} textAlign="right">
                Quantity
              </PDFTableCell>
              <PDFTableCell head width={80} textAlign="right">
                Unit Price
              </PDFTableCell>
              <PDFTableCell head width={83} textAlign="right">
                Amount
              </PDFTableCell>
            </PDFTableRow>

            {products.map((product: PurchaseOrderPDFInfo_Product, index: number) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCellView width={284} flexDirection="column">
                  <Text>{product.code}</Text>
                  <Text>{product.nameEn || product.name}</Text>
                </PDFTableCellView>
                <PDFTableCell width={64} textAlign="right">
                  {numeral(product.amount).format('0,0')} {product.unitEn || product.unit}
                </PDFTableCell>
                <PDFTableCell width={80} textAlign="right">
                  {numeral(product.price).format('0,0.00')}
                </PDFTableCell>
                <PDFTableCell width={83} textAlign="right">
                  {!!product.price ? numeral(product.amount * product.price).format('0,0.00') : '0.00'}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="Total amount (in word)">{converter.toWords(purchaseOrderPDFInfo.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="Remark" color="error">
                  {purchaseOrderPDFInfo.remark}
                </PDFInfo>
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {priceBreakdowns
                .filter((item) => item.value > 0)
                .map((price) => (
                  <PDFInfo key={price.label} label={price.label} direction="row" dataWidth={140}>
                    {numeral(price.value).format('0,0.00')}
                  </PDFInfo>
                ))}
            </PDFView>
          </PDFView>

          <PDFView bottomLine="darkGrey">
            <PDFRow>
              <PDFColumn columnSpan={6}>
                <PDFInfo label="Delivery Address">
                  {!!customerShippingAddress ? (
                    <Text>
                      {customerShippingAddress.name} {getAddressText(customerShippingAddress)}
                    </Text>
                  ) : (
                    '519/174-176 soi Prachaautid 121 Thungkru Bangkok Thailand 10140'
                  )}
                </PDFInfo>
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <Text>Contact Address</Text>
                {!!customerShippingAddress ? (
                  <>
                    <Text>{customerShippingAddress.consigneeName}</Text>
                    <Text>Tel {customerShippingAddress.phoneNumber}</Text>
                  </>
                ) : (
                  <>
                    <Text>Purchase</Text>
                    <Text>Tel 02-114-8050</Text>
                  </>
                )}
              </PDFColumn>
            </PDFRow>
          </PDFView>

          <PDFView bottomLine="darkGrey">
            <PDFRow>
              <Text style={styles.center}>
                *** Please attach the purchase order, every time a bill is sent or a check is received ***
              </Text>
              <Text style={styles.center}>
                The company has the right to return the product to the seller or cancel the order.
              </Text>
              <Text style={styles.center}>If the delivered goods are not as specified in the purchase order.</Text>
              <Text style={styles.center}>
                The purchase order is effective only with the authorized signature of the authorized person.
              </Text>
            </PDFRow>
          </PDFView>

          <View wrap={false}>
            <PDFRow>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="Recieved By" date={purchaseOrderPDFInfo.recCreatedAt} english={true} />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="Purchasing" date={purchaseOrderPDFInfo.recCreatedAt} english={true} />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="Checked By" date={purchaseOrderPDFInfo.recCreatedAt} english={true} />
              </PDFColumn>
              <PDFColumn columnSpan={3}>
                <PDFSignatureView title="Authorized Signature" english={true} />
              </PDFColumn>
            </PDFRow>
          </View>

          <PDFPagination />
        </Page>
        {showedImagesPage && (
          <Page size="A4" style={styles.page}>
            <Text style={styles.title} fixed>
              All products from purchase order
            </Text>

            <PDFRow align="flex-start">
              {products
                .filter((p) => !!p.imageUrl)
                .map((product: PurchaseOrderPDFInfo_Product, index: number) => (
                  <PDFColumn columnSpan={4} key={index}>
                    <Image
                      style={styles.imageProductGallery}
                      src={{
                        uri: product.imageUrl!,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                        body: '',
                      }}
                    />
                    <PDFInfo label={product.code}>
                      <Text>{product.name}</Text>
                    </PDFInfo>
                  </PDFColumn>
                ))}
            </PDFRow>
          </Page>
        )}
      </Document>
    </SoraPDFViewer>
  )
}

export default PurchaseOrderPDFEnglishPage
