import { Box, Paper, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import React, { useContext } from 'react'
import { FlexBox, SubTopic } from '../../../custom-components'
import { Space } from '../../../shared-components'
import { QuotationsProfilePageContextImpl } from './QuotationsProfilePage'

const QuotationsEvaluationResult: React.FC = () => {
  const { profile } = useContext(QuotationsProfilePageContextImpl)

  return (
    <Box p={3} clone>
      <Paper>
        <FlexBox justifyContent="space-between">
          <SubTopic gutterBottom>ข้อมูลการประเมินวิเคราะห์ใบเสนอราคา</SubTopic>
        </FlexBox>
        <Space />
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="left">คำถาม</TableCell>
              <TableCell align="center">คำตอบ</TableCell>
              <TableCell align="center">คะแนน</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {profile.evaluationResult?.resultState.answers.map((answer) => (
              <TableRow>
                <TableCell align="left">{answer.question}</TableCell>
                <TableCell align="center">{answer.name}</TableCell>
                <TableCell align="center">{answer.score}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </Box>
  )
}

export default QuotationsEvaluationResult
