import { BaseData, UserLogin, UserPermission, UserProfile } from '../api/smartbestbuys-api'
import { INotification, Notification } from '../api/smartbestbuys-api/general/general-types'
import { version } from '../../package.json'
import { UserAction } from '../enums'
import { AddressData, UserData } from '../api/smartbestbuys-api/data/data-types'

export const SESSION_KEY_PREFIX = 'smartbestbuys-auth-token-'
export const SESSION_KEY = `${SESSION_KEY_PREFIX}${version}`

export const getToken = () => window.localStorage.getItem(SESSION_KEY)

export const setToken = (token: string) => window.localStorage.setItem(SESSION_KEY, token)

export const setUser = (user: UserLogin) => window.localStorage.setItem('user', JSON.stringify(user))

export const getUser = (): UserLogin | undefined => {
  const userString = window.localStorage.getItem('user')
  return !!userString ? JSON.parse(userString) : undefined
}

export const setProfileToLocalStorage = (profile: UserProfile) =>
  window.localStorage.setItem('profile', JSON.stringify(profile))

export const getProfileFromLocalStorage = (): UserProfile | undefined => {
  const profileString = window.localStorage.getItem('profile')
  return !!profileString && profileString !== undefined ? JSON.parse(profileString) : undefined
}

export const checkSession = () => getToken()

export const clearSession = () => {
  window.localStorage.clear()
  window.location.href = '/login'
}

export const setPermission = (permission: UserPermission) => {
  window.localStorage.setItem('permission', JSON.stringify(permission))
}

export const getPermission = () => {
  const permission = window.localStorage.getItem('permission')
  return permission ? JSON.parse(permission) : undefined
}

export const getNotificationFromLocalStorage = (): { notification: INotification; expiredTimes: number } => {
  const notificationStorage = window.localStorage.getItem(`smart-data_notification`)
  return notificationStorage ? JSON.parse(notificationStorage) : undefined
}

export const saveLocalNotification = (notification: INotification, expiredTimes: number) => {
  window.localStorage.setItem('smart-data_notification', JSON.stringify({ notification, expiredTimes }))
}

export const loadNotification = (): Notification => {
  const notificationStorage = window.localStorage.getItem('smart-data_notification')
  const notificationData: { notification: INotification; expiredTimes: number } = JSON.parse(
    notificationStorage ?? '{}',
  )
  if (!notificationData?.notification) return new Notification()

  return new Notification(notificationData.notification)
}

export const getActionMessagesFromLocalStorage = (
  actionId: UserAction,
): { actionMessages: BaseData[]; expiredTimes: number } => {
  const actionMessagesStorage = window.localStorage.getItem(`smart-data_action-${actionId}`)
  return actionMessagesStorage ? JSON.parse(actionMessagesStorage) : undefined
}

export const setActionMessageToLocalStorage = (
  actionId: UserAction,
  actionMessages: BaseData[],
  expiredTimes: number,
) => {
  window.localStorage.setItem(`smart-data_action-${actionId}`, JSON.stringify({ actionMessages, expiredTimes }))
}

export const getUserDataFromLocalStorage = (): { users: UserData[]; expiredTimes: number } => {
  const usersStorage = window.localStorage.getItem(`smart-data_user-data`)
  return usersStorage ? JSON.parse(usersStorage) : undefined
}

export const setUserDataToLocalStorage = (users: UserData[], expiredTimes: number) => {
  window.localStorage.setItem('smart-data_user-data', JSON.stringify({ users, expiredTimes }))
}

export const getAddressDataFromLocalStorage = (): { address: AddressData; expiredTimes: number } => {
  const addressStorage = window.localStorage.getItem(`smart-data_address-data`)
  return addressStorage ? JSON.parse(addressStorage) : undefined
}

export const setAdressDataToLocalStorage = (address: AddressData, expiredTimes: number) => {
  window.localStorage.setItem(`smart-data_address-data`, JSON.stringify({ address, expiredTimes }))
}
