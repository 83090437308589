export interface IQuotationEvalutationForm {
  id: number
  name: string
  maxScore: number
  questions: IQuotationEvalutationForm_Question[]
}

export interface IQuotationEvalutationForm_Question {
  key: string
  question: string
  sort: number
  choices: { name: string; score: number }[]
}

export const quotationEvaluationForm: IQuotationEvalutationForm = {
  id: 1,
  name: 'Quotation Evaluation Form',
  maxScore: 26,
  questions: [
    {
      key: 'quotation_evaluation_form_q1',
      question: 'ลักษณะการซื้อของลูกค้าเป็นอย่างไร ?',
      sort: 1,
      choices: [
        {
          name: 'ทดแทนตัวเดิม',
          score: 3,
        },
        {
          name: 'ซื้อเพิ่มของเดิม',
          score: 2,
        },
        {
          name: 'สินค้าใหม่ หรือ ลูกค้าใหม่',
          score: 1,
        },
      ],
    },
    {
      key: 'quotation_evaluation_form_q2',
      sort: 2,
      question: 'ลูกค้ามีคู่เทียบใน Project นี้มั้ย ?',
      choices: [
        {
          name: 'ไม่มี',
          score: 3,
        },
        {
          name: 'รายเดียว',
          score: 2,
        },
        {
          name: 'มากกว่า 1 ราย',
          score: 1,
        },
      ],
    },
    {
      key: 'quotation_evaluation_form_q3',
      sort: 3,
      question: 'ลูกค้ามีกำหนดการใช้งานเมื่อไหร่ ?',
      choices: [
        {
          name: 'พร้อมเลย',
          score: 3,
        },
        {
          name: 'รออนุมัติ',
          score: 2,
        },
        {
          name: 'ยังไม่มีกำหนดแน่ชัด',
          score: 0,
        },
      ],
    },
    {
      key: 'quotation_evaluation_form_q4',
      sort: 4,
      question: 'ความพร้อมหน้างานของลูกค้า ?',
      choices: [
        {
          name: 'พร้อมเลย',
          score: 3,
        },
        {
          name: 'รอเคลียหน้างานภายใน 1 เดือน',
          score: 2,
        },
        {
          name: 'รอเคลียหน้างานมากกว่า 1 เดือน',
          score: 1,
        },
      ],
    },
    {
      key: 'quotation_evaluation_form_q5',
      sort: 5,
      question: 'ลูกค้ารู้จักเราจากช่องทางไหน ?',
      choices: [
        {
          name: 'ลูกค้าเก่า',
          score: 3,
        },
        {
          name: 'มีคนแนะนำ',
          score: 2,
        },
        {
          name: 'ไม่มี',
          score: 0,
        },
      ],
    },
    {
      key: 'quotation_evaluation_form_q6',
      sort: 6,
      question: 'งบประมาณของลูกค้าสมเหตุสมผลหรือไม่ ?',
      choices: [
        {
          name: 'ใช่',
          score: 5,
        },
        {
          name: 'ไม่ใช่',
          score: 0,
        },
      ],
    },
    {
      key: 'quotation_evaluation_form_q7',
      sort: 7,
      question: 'ลูกค้าที่กำลังประสานงานมีอำนาจตัดสินใจโดยตรงหรือไม่ ?',
      choices: [
        {
          name: 'ใช่',
          score: 3,
        },
        {
          name: 'ไม่ใช่แต่มีอิทธิพลสูง',
          score: 2,
        },
        {
          name: 'คนนำเสนอ',
          score: 1,
        },
      ],
    },
    {
      key: 'quotation_evaluation_form_q8',
      sort: 8,
      question: '(ให้เช็คว่าเรามีสินค้าพร้อมส่งมั้ย) ถ้าไม่มีสินค้าพร้อมส่ง ลูกค้ารอได้หรือไม่ ?',
      choices: [
        {
          name: 'พร้อมส่ง',
          score: 3,
        },
        {
          name: 'ลูกค้ารอได้',
          score: 2,
        },
        {
          name: 'รอไม่ได้',
          score: 1,
        },
      ],
    },
  ],
}
