import { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  Avatar,
  Box,
  Button,
  Card,
  Grid,
  MenuItem,
  Paper,
  Popover,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core'
import { MoreHoriz, Cancel, CheckCircle, Add, Photo } from '@material-ui/icons'
import { RootContext } from '../../..'
import { PageName, PurchaseOrderStatus, PurchaseRequisitionStatus } from '../../../enums'
import PurchaseRequisitionCancelationDialog from '../PurchaseRequisitionCancelationDialog'
import {
  BreadcumbsList,
  Topic,
  withLayout,
  FlexBox,
  SubTopic,
  TypographyWithLabel,
  Link,
  Table,
  CustomButton,
  EmptyCard,
  Title,
} from '../../../custom-components'
import { usePurchaseRequisitionProfile } from '../../../hooks'
import {
  ConditionTooltip,
  Loading,
  PurchaseOrderPaymentStatusChip,
  PurchaseOrderStatusChip,
  PurchaseRequisitionStatusChip,
  UserActivityTable,
  MultipleConditionTooltip,
} from '../../../shared-components'
import { VendorInfoCard } from '../create/VendorBox/VendorInfoCard'
import './PurchaseRequisitionProfilePage.less'
import {
  PurchaseProduct,
  PurchaseRequisitionActivity,
  PurchaseRequisitionProfile_PurchaseOrder,
} from '../../../api/smartbestbuys-api'
import numeral from 'numeral'
import { format } from 'date-fns'
import thLocale from 'date-fns/locale/th'
import FileCard from '../create/AttachmentSelectionBox/FileCard'
import { FileType, SourceFile } from '../../../types'
import PurchaseRequisitionApprovalDialog from '../PurchaseRequisitionApprovalDialog'
import PurchaseRequisitionRejectDialog from '../PurchaseRequisitionRejectDialog'
import { dateFormat, dateTimeFormat } from '../../../functions'
import { PermissionEnum } from '../../../enums/PermissionEnum'

import { getUserPermissionInfo } from '../../../utils/permission'

interface Props {
  id: number
}

const PurchaseRequisitionProfilePage = (props: Props) => {
  const history = useHistory()

  const readPermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Read)

  if (!readPermission.hasPermission) {
    history.push('/')
  }

  const { id } = props
  const { setCurrentPage, triggerSnackbar } = useContext(RootContext)
  const [reload, setReload] = useState(false)
  const [cancelOpen, setCancelOpen] = useState(false)
  const [approveOpen, setApproveOpen] = useState(false)
  const [rejectOpen, setRejectOpen] = useState(false)

  setCurrentPage(PageName.PURCHASE_REQUISITION)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => setAnchorEl(event.currentTarget)
  const handleClose = () => setAnchorEl(null)
  const open = Boolean(anchorEl)

  const profile = usePurchaseRequisitionProfile(id, reload)

  if (!profile) return <Loading />

  // data
  const cancelPurchaseRequisitionStatus = [PurchaseRequisitionStatus.PendingReview, PurchaseRequisitionStatus.Approved]

  const breadcumbsItems = [{ url: '/purchase-requisitions', name: 'รายการคำขอซื้อ' }, { name: profile.code }]
  const isPendingReview = profile.status.id === PurchaseRequisitionStatus.PendingReview
  const isApproved = profile.status.id === PurchaseRequisitionStatus.Approved
  const hasOpenedPurchaseOrder = !!profile.relatedPurchaseOrders?.find(
    (p) => p.status.id !== PurchaseOrderStatus.Rejected && p.status.id !== PurchaseOrderStatus.Canceled,
  )

  // flag

  const isShowEditMenu = isPendingReview && !hasOpenedPurchaseOrder
  const isShowCancelMenu = cancelPurchaseRequisitionStatus.includes(profile.status.id)

  // permission
  const writePermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Write)
  const cancelPermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Cancel)
  const reviewPermission = getUserPermissionInfo(PermissionEnum.Purchase_PR_Review)
  const writePOPermission = getUserPermissionInfo(PermissionEnum.Purchase_PO_Write)

  // condition items
  const createPOConditions = [
    { showTooltip: !writePOPermission.hasPermission, message: writePOPermission.alertMessage },
    { showTooltip: !isApproved, message: 'กรุณาอนุมัติคำขอซื้อก่อน' },
  ]

  return (
    <>
      <PurchaseRequisitionCancelationDialog
        purchaseRequisitionId={profile.id}
        purchaseRequisitionCode={profile.code}
        open={cancelOpen}
        onClose={() => setCancelOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ยกเลิกคำขอซื้อเลขที่ ${profile.code} สำเร็จ`)
          setCancelOpen(false)
          setReload((prev) => !prev)
        }}
        onFail={() => {
          triggerSnackbar(`ยกเลิกคำขอซื้อเลขที่ ${profile.code} ไม่สำเร็จ`)
        }}
      />
      <PurchaseRequisitionApprovalDialog
        purchaseRequisitionId={profile.id}
        open={approveOpen}
        onClose={() => setApproveOpen(false)}
        onComplete={() => {
          triggerSnackbar(`อนุมัติคำขอซื้อเลขที่ ${profile.code} สำเร็จ`)
          setApproveOpen(false)
          setReload((prev) => !prev)
        }}
        onFail={() => {
          triggerSnackbar(`อนุมัติคำขอซื้อเลขที่ ${profile.code} ไม่สำเร็จ`)
        }}
      />
      <PurchaseRequisitionRejectDialog
        purchaseRequisitionId={profile.id}
        open={rejectOpen}
        onClose={() => setRejectOpen(false)}
        onComplete={() => {
          triggerSnackbar(`ไม่อนุมัติคำขอซื้อเลขที่ ${profile.code} สำเร็จ`)
          setRejectOpen(false)
          setReload((prev) => !prev)
        }}
        onFail={() => {
          triggerSnackbar(`ไม่อนุมัติคำขอซื้อเลขที่ ${profile.code} ไม่สำเร็จ`)
        }}
      />
      <Box p={2} className="purchase-requisition-box">
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <BreadcumbsList items={breadcumbsItems} />
          </Grid>
          <Grid item xs={12}>
            <FlexBox justifyContent="space-between">
              <Topic>คำขอซื้อ {profile.code}</Topic>
              {(isShowEditMenu || isShowCancelMenu) && (
                <>
                  <Button variant="contained" size="large" onClick={handleClick}>
                    <MoreHoriz />
                  </Button>
                  <Popover
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    {isShowEditMenu && (
                      <ConditionTooltip
                        showTooltip={!writePermission.hasPermission}
                        title={writePermission.alertMessage}
                      >
                        <MenuItem
                          disabled={!writePermission.hasPermission}
                          onClick={() => history.push(`/purchase-requisitions/${id}/update`)}
                        >
                          แก้ไขคำขอซื้อ
                        </MenuItem>
                      </ConditionTooltip>
                    )}
                    {isShowCancelMenu && (
                      <ConditionTooltip
                        showTooltip={!cancelPermission.hasPermission}
                        title={cancelPermission.alertMessage}
                      >
                        <MenuItem disabled={!cancelPermission.hasPermission} onClick={() => setCancelOpen(true)}>
                          ยกเลิกคำขอซื้อ
                        </MenuItem>
                      </ConditionTooltip>
                    )}
                  </Popover>
                </>
              )}
            </FlexBox>
          </Grid>
          <Grid item xs={12} container justify="space-between" alignItems="center">
            <PurchaseRequisitionStatusChip status={profile.status.id} label={profile.status.name} />
            <MultipleConditionTooltip conditionItems={createPOConditions}>
              <Button
                variant="contained"
                color="primary"
                disabled={createPOConditions.some((cond) => cond.showTooltip)}
                onClick={() => {
                  history.push(`/purchase-orders/${id}/create`)
                }}
              >
                <Add fontSize="small" className="mr-2" /> สร้างใบสั่งซื้อ
              </Button>
            </MultipleConditionTooltip>
          </Grid>
          {reviewPermission.hasPermission && isPendingReview && (
            <Grid item xs={12}>
              <Paper className="purchase-requisition-box__check-list">
                <Grid container spacing={2}>
                  <Grid item xs={12} className="purchase-requisition-box__check-list__header">
                    <SubTopic>ข้อมูลที่ต้องรีวิว</SubTopic>
                    <div>
                      <CustomButton variant="contained" color="error" onClick={() => setRejectOpen(true)}>
                        <Cancel fontSize="small" className="mr-2" /> ไม่อนุมัติ
                      </CustomButton>
                      <span className="mr-2" />
                      <CustomButton variant="contained" color="success" onClick={() => setApproveOpen(true)}>
                        <CheckCircle fontSize="small" className="mr-2" /> อนุมัติ
                      </CustomButton>
                    </div>
                  </Grid>
                  <Grid item xs={12}>
                    <ul className="purchase-requisition-box__check-list__list">
                      {profile.prCheckList?.map((item, index) => (
                        <li key={`list-${index}`} className="ml-8">
                          <Typography color="textSecondary">{item}</Typography>
                        </li>
                      ))}
                    </ul>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}
          <Grid item xs={8}>
            <Card className="purchase-requisition-box__card">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SubTopic>ข้อมูลคำข้อซื้อ</SubTopic>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="ประเภทคำขอซื้อ:">{profile.type.name}</TypographyWithLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="เหตุผลการเปิด:">{profile.topic.name}</TypographyWithLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="สกุลเงินที่ใช้:">{profile.currencyInfo.name}</TypographyWithLabel>
                </Grid>
                <Grid item xs={12} md={6}>
                  <TypographyWithLabel label="วันที่ต้องการสินค้า/บริการ:">
                    {profile.dueDate ? format(new Date(profile.dueDate), 'dd MMM yyyy', { locale: thLocale }) : '-'}
                  </TypographyWithLabel>
                </Grid>
                {!!profile.saleOrderInfo && (
                  <>
                    <Grid item xs={12} md={6}>
                      <TypographyWithLabel label="ใบสั่งขายอ้างอิง:">
                        <Link color="secondary" to={`/sale-orders/${profile.saleOrderInfo.id}`}>
                          {profile.saleOrderInfo.code}
                        </Link>
                      </TypographyWithLabel>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <TypographyWithLabel label="วันที่ลูกค้าต้องการ:">
                        {profile.saleOrderInfo.dueDate
                          ? format(new Date(profile.saleOrderInfo.dueDate), 'dd MMM yyyy', { locale: thLocale })
                          : '-'}
                      </TypographyWithLabel>
                    </Grid>
                  </>
                )}
              </Grid>
            </Card>
          </Grid>
          <Grid item xs={4}>
            <Paper className="purchase-requisition-box__vendor-card">
              <SubTopic className="purchase-requisition-box__vendor-card__title">ข้อมูลผู้ขายอ้างอิง</SubTopic>
              <VendorInfoCard vendor={profile.referenceVendorInfo} readonly />
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <Paper className="purchase-requisition-box__purchase-product">
              <SubTopic className="purchase-requisition-box__purchase-product__title">ข้อมูลสินค้า/บริการ</SubTopic>
              <Table<PurchaseProduct>
                color="primary.dark"
                size="small"
                columns={[
                  {
                    name: 'ลำดับ',
                    fixedWidth: 50,
                    render: (_, index) => index + 1,
                  },
                  {
                    name: 'ชื่อสินค้า/บริการ',
                    dataIndex: 'name',
                    render: (product) => (
                      <div className="purchase-requisition-products-table__product-cell" key={'product-' + product.id}>
                        <Avatar alt={product.name} src={product.imageUrl} variant="rounded">
                          <Photo />
                        </Avatar>
                        <div className="purchase-requisition-products-table__product-cell__info">
                          <Typography color="textSecondary">{product.code}</Typography>
                          <Typography>{product.name}</Typography>
                          {product.nameEn && <Typography>{product.nameEn}</Typography>}
                        </div>
                      </div>
                    ),
                  },
                  {
                    name: 'จำนวน',
                    numeric: true,
                    format: '0,0',
                    dataIndex: 'amount',
                  },
                  {
                    name: 'ราคาต่อหน่วย',
                    numeric: true,
                    dataIndex: 'price',
                    render: (product) =>
                      product.price ? (
                        numeral(product.price).format('0,0.00')
                      ) : (
                        <Typography color="textSecondary">ไม่ระบุ</Typography>
                      ),
                  },
                  {
                    name: 'ราคารวม',
                    numeric: true,
                    render: (product) => {
                      if (!!product.price) return numeral(product.amount * product.price).format('0,0.00')
                      return <Typography color="textSecondary">คำนวณไม่ได้</Typography>
                    },
                  },
                ]}
                data={profile.products}
                postCells={
                  <TableRow>
                    <TableCell colSpan={3} />
                    <TableCell align="right">รวมเงิน</TableCell>
                    <TableCell align="right">
                      {profile.priceInfo.total ? (
                        numeral(profile.priceInfo.total).format('0,0.00')
                      ) : (
                        <Typography color="textSecondary">คำนวณไม่ได้</Typography>
                      )}
                    </TableCell>
                  </TableRow>
                }
                pagination={false}
                cleanSpace
              />
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className="purchase-requisition-box__purchase-order-list">
              <Title className="purchase-requisition-box__purchase-order-list__title">
                รายการใบสั่งซื้อของคำขอซื้อนี้
              </Title>
              {profile.relatedPurchaseOrders && profile.relatedPurchaseOrders.length > 0 ? (
                <Table<PurchaseRequisitionProfile_PurchaseOrder>
                  color="primary.dark"
                  size="small"
                  columns={[
                    {
                      name: 'สถานะใบสั่งซื้อ',
                      dataIndex: 'status',
                      render: ({ status }) => <PurchaseOrderStatusChip status={status.id} label={status.name} />,
                    },
                    {
                      name: 'สถานะการจ่ายเงิน',
                      dataIndex: 'paymentStatus',
                      render: ({ paymentStatus }) => (
                        <PurchaseOrderPaymentStatusChip status={paymentStatus.id} label={paymentStatus.name} />
                      ),
                    },
                    {
                      name: 'วันที่สร้าง',
                      render: ({ recCreatedAt }) => dateTimeFormat(recCreatedAt),
                    },
                    {
                      name: 'วันที่ตกลงกับผู้ขาย',
                      render: ({ vendorShippingDate }) =>
                        vendorShippingDate ? (
                          dateFormat(vendorShippingDate)
                        ) : (
                          <Typography color="textSecondary">ยังไม่นัดหมาย</Typography>
                        ),
                    },
                    {
                      name: 'วันที่ลูกค้าต้องการ',
                      render: ({ dueDateSaleOrder }) =>
                        dueDateSaleOrder ? (
                          dateFormat(dueDateSaleOrder)
                        ) : (
                          <Typography color="textSecondary">ไม่ระบุ</Typography>
                        ),
                    },
                    {
                      name: 'เลขที่ใบสั่งซื้อ',
                      dataIndex: 'code',
                    },
                    {
                      name: 'ผู้ขาย',
                      dataIndex: 'vendor',
                      render: ({ vendor }) => vendor.name,
                    },
                    { name: 'ยอดรวมสุทธิ', dataIndex: 'total', numeric: true, format: '0,0.00' },
                  ]}
                  data={profile.relatedPurchaseOrders}
                  pagination={false}
                  cleanSpace
                />
              ) : (
                <EmptyCard emptyText="ยังไม่มีใบสั่งซื้อ" />
              )}
            </Paper>
          </Grid>

          <Grid item xs={12}>
            <Paper className="purchase-requisition-box__attachment">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <SubTopic className="purchase-requisition-box__attachment__title">ไฟล์แนบเพิ่มเติม</SubTopic>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {profile.attachments &&
                      profile.attachments.map((attach) => {
                        const sourceFile: SourceFile = {
                          base64: attach.attachmentUrl,
                          name: attach.attachmentUrl,
                          type: FileType.Image,
                        }
                        return (
                          <Grid item xs={12} md={2} key={sourceFile.name}>
                            <FileCard value={sourceFile} readonly />
                          </Grid>
                        )
                      })}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12}>
            <UserActivityTable<PurchaseRequisitionActivity>
              title="ประวัติการจัดการคำขอซื้อ"
              activities={profile.activities ?? []}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  )
}

export default withLayout(PurchaseRequisitionProfilePage)
