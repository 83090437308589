import {
  RawDocument,
  PartialUpdateDocumentRequestBody,
  GetTaxInvoiceTableDataQueryParams,
  ITaxInvoiceTableData,
  IInvoiceTableData,
  IReceiptTableData,
  IBillingNoteTableData,
  ICreditNoteTableData,
  GetDocumentTableDataQueryParams,
} from '..'
import { errorHandler, errorSingleHandler } from '../../middleware'
import smartAuthAPI from '../smartbestbuys-api'

export const partialUpdateDocumentAPI = errorSingleHandler<RawDocument>(
  async (id: number, request: PartialUpdateDocumentRequestBody) => {
    return smartAuthAPI.patch(`/documents/${id}/partial-update`, request)
  },
)

export const getTaxInvoiceTableDataAPI = errorHandler<ITaxInvoiceTableData>(
  async (queryParams: GetTaxInvoiceTableDataQueryParams) => {
    return await smartAuthAPI.get('/documents/tax-invoices/table-data', {
      params: queryParams,
    })
  },
)

export const getInvoiceTableDataAPI = errorHandler<IInvoiceTableData>(
  async (queryParams: GetDocumentTableDataQueryParams) => {
    return await smartAuthAPI.get('/documents/invoices/table-data', {
      params: queryParams,
    })
  },
)

export const getReceiptTableDataAPI = errorHandler<IReceiptTableData>(
  async (queryParams: GetDocumentTableDataQueryParams) => {
    return await smartAuthAPI.get('/documents/receipts/table-data', {
      params: queryParams,
    })
  },
)

export const getBillingNoteTableDataAPI = errorHandler<IBillingNoteTableData>(
  async (queryParams: GetDocumentTableDataQueryParams) => {
    return await smartAuthAPI.get('/documents/billing-notes/table-data', {
      params: queryParams,
    })
  },
)

export const getCreditNoteTableDataAPI = errorHandler<ICreditNoteTableData>(
  async (queryParams: GetDocumentTableDataQueryParams) => {
    return await smartAuthAPI.get('/documents/credit-notes/table-data', {
      params: queryParams,
    })
  },
)
