import smartAuthAPI from '../smartbestbuys-api'
import { errorHandler, errorSingleHandler } from '../../middleware'
import {
  CreatedInfo,
  GetPurchaseRequisitionTableDataRequestQueryParams,
  IPurchaseRequisitionTableData,
  PRCreationRequest,
  PRUpdateRequest,
  PurchaseRequisitionCreateData,
  PurchaseRequisitionDataRow,
  PurchaseRequisitionInfo,
  PurchaseRequisitionProfile,
  PurchaseRequisitionUpdateData,
  PurchaseRequisitionUpsertDataParams,
  UpdatedPurchaseRequisitionStatus,
} from './types'
import { FetchRequest, UserActivityNote } from '../types'
import { dateSqlFormat } from '../../../functions/date-formatter'

export const createPurchaseRequisitionApi = errorHandler<CreatedInfo>((request: PRCreationRequest) => {
  const path = 'purchase/v1/purchase-requisitions/create'
  return smartAuthAPI.post(path, request)
})

export const getCreatePurchaseRequisitions = errorHandler<PurchaseRequisitionCreateData>(
  (queryParams: PurchaseRequisitionUpsertDataParams) => {
    return smartAuthAPI.get('purchase/v1/purchase-requisitions/create-data', {
      params: {
        saleOrderId: queryParams.saleOrderId,
        rop: queryParams.rop,
      },
    })
  },
)

export const fetchPurchaseRequisitions = errorHandler<PurchaseRequisitionDataRow[]>((request: FetchRequest) => {
  return smartAuthAPI.get('purchase/v1/purchase-requisitions/fetch', {
    params: {
      startDate: dateSqlFormat(request.startDate),
      endDate: dateSqlFormat(request.endDate),
    },
  })
})

export const getPurchaseRequisitionProfile = errorHandler<PurchaseRequisitionProfile>(async (id: number) => {
  return smartAuthAPI.get(`purchase/v1/purchase-requisitions/${id}/profile`)
})

export const cancelPurchaseRequisition = errorSingleHandler<UpdatedPurchaseRequisitionStatus>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`purchase/v1/purchase-requisitions/${id}/cancel`, request)
  },
)

export const approvePurchaseRequisition = errorSingleHandler<UpdatedPurchaseRequisitionStatus>(async (id: number) => {
  return smartAuthAPI.post(`purchase/v1/purchase-requisitions/${id}/approve`)
})

export const rejectPurchaseRequisition = errorSingleHandler<UpdatedPurchaseRequisitionStatus>(
  async (id: number, request: UserActivityNote) => {
    return smartAuthAPI.post(`purchase/v1/purchase-requisitions/${id}/reject`, request)
  },
)

export const updatePurchaseRequisitionApi = errorSingleHandler<PurchaseRequisitionInfo>(
  (purchaseRequisitionId: number, request: PRUpdateRequest) => {
    const path = `/purchase/v1/purchase-requisitions/${purchaseRequisitionId}/update`

    return smartAuthAPI.put(path, request)
  },
)

export const getUpdatePurchaseRequisitions = errorHandler<PurchaseRequisitionUpdateData>(
  (purchaseRequisitionId: number) => {
    const path = `/purchase/v1/purchase-requisitions/${purchaseRequisitionId}/update-data`

    return smartAuthAPI.get(path)
  },
)

export const getPurchaseRequisitionTableDataAPI = errorHandler<IPurchaseRequisitionTableData>(
  async (queryParams: GetPurchaseRequisitionTableDataRequestQueryParams) => {
    return await smartAuthAPI.get(`/purchase/v1/purchase-requisitions/table-data`, {
      params: queryParams,
    })
  },
)
