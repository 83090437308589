import { Document, Page, Image, Text, Font, View } from '@react-pdf/renderer'
import React from 'react'
import {
  SoraPDFViewer,
  PDFTable,
  PDFTableRow,
  PDFTableCell,
  PDFView,
  PDFInfo,
  PDFSignatureView,
  PDFRow,
  PDFColumn,
  PDFPagination,
  Loading,
} from '../../../shared-components'
import { styles } from './QuotationsPdfStyle'
import smartBestBuysLogo from '../../../assets/images/new-logo.png'
import lineContact from '../../../assets/images/line-contact.png'
import { useQuotationsProfile } from '../../../hooks/quotation/useQuotaionsProfile'
import { dateFormat, getAddressText, getPricebreakdown } from '../../../functions'
import { RouteComponentProps } from 'react-router-dom'
import { QuotationProfile_Product } from '../../../api/smartbestbuys-api/quotation/quotation-profile-types'
import numeral from 'numeral'
import { ThaiBaht } from 'thai-baht-text-ts'

Font.register({ family: 'THSarabunNewBold', src: '/fonts/THSarabunNew-Bold.ttf' })

const QuotationsPDF: React.FC<RouteComponentProps<any>> = (props) => {
  const { id } = props.match.params
  const quotationProfile = useQuotationsProfile(id)
  if (!quotationProfile) return <Loading />

  const priceBreakdowns = getPricebreakdown(quotationProfile)

  const { code, products, customer, contact, billingAddress, recCreatedAt, recCreatedBy, expiredDate, assigneeUser } =
    quotationProfile

  document.title = `ใบเสนอราคา ${quotationProfile.code}`

  const showedImagesPage = !!products.find((p) => !!p.imageUrl)

  return (
    <SoraPDFViewer>
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Header */}
          <PDFRow style={styles.spaceBetween} fixed>
            <PDFColumn columnSpan={2}>
              <Image style={styles.logo} src={smartBestBuysLogo} />
            </PDFColumn>
            <PDFColumn columnSpan={6}>
              <PDFView style={styles.contactPointBox}>
                <Text style={styles.bold}>บริษัท สมาร์ท เบสท์บายส์ จำกัด (สำนักงานใหญ่) </Text>
                <PDFView style={styles.textMini}>
                  <Text>www.smartbestbuys.com</Text>
                  <Text>
                    <Text style={styles.bold}>เลขประจำตัวผู้เสียภาษี: </Text>0105548090347
                  </Text>
                  <Text>
                    <Text style={styles.bold}>ที่อยู่: </Text>519/174-176 ซอยประชาอุทิศ 121แขวงทุ่งครุ เขตทุ่งครุ
                    กรุงเทพฯ 10140
                  </Text>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>โทร: </Text>02-1147006
                    </Text>
                    <Text>
                      <Text style={styles.bold}>โทรสาร: </Text>02 462-8274
                    </Text>
                  </PDFView>
                  <PDFView style={styles.spaceBetween}>
                    <Text>
                      <Text style={styles.bold}>อีเมล: </Text>sale@smartbestbuys.com
                    </Text>
                    <Text>
                      <Text style={styles.bold}>ไอดีไลน์: </Text>@trafficthai
                    </Text>
                  </PDFView>
                </PDFView>
              </PDFView>
            </PDFColumn>
            <PDFColumn style={{ paddingLeft: 12 }} borders={['left']} borderColor={'light'} columnSpan={4}>
              <PDFInfo label="เลขที่เอกสาร" direction="row" dataWidth={88} noMargin>
                {code}
              </PDFInfo>
              {recCreatedAt && (
                <PDFInfo label="วันที่" direction="row" dataWidth={88} noMargin>
                  {dateFormat(recCreatedAt)}
                </PDFInfo>
              )}
              <PDFInfo label="ยืนราคาถึงวันที่" direction="row" dataWidth={88} noMargin>
                {dateFormat(expiredDate)}
              </PDFInfo>
              {assigneeUser?.name && (
                <PDFInfo label="ผู้รับผิดชอบงาน" direction="row" dataWidth={88} noMargin>
                  {assigneeUser?.name}
                </PDFInfo>
              )}
              <PDFInfo label="ผู้จัดทำเอกสาร" direction="row" dataWidth={88} noMargin>
                {recCreatedBy}
              </PDFInfo>
            </PDFColumn>
          </PDFRow>
          {/* Title */}
          <Text style={styles.topic} fixed>
            ใบเสนอราคา
          </Text>
          {/* SubHeader */}
          <PDFRow borderColor="light">
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ชื่อลูกค้า">
                {billingAddress?.overrideDocumentData?.customerName || customer?.nameLocal || '-'}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เลขประจำตัวผู้เสียภาษี">
                {billingAddress?.overrideDocumentData?.taxNumber || customer?.referenceNumber || '-'}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={8}>
              <PDFInfo label="ที่อยู่">
                {billingAddress?.overrideDocumentData?.address ||
                  (billingAddress ? getAddressText(billingAddress) : '-')}
              </PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="ผู้ติดต่อ">{contact?.nicknameLocal || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรศัพท์">{customer?.phoneNumber || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="โทรสาร">{billingAddress?.faxNumber || '-'}</PDFInfo>
            </PDFColumn>
            <PDFColumn columnSpan={4}>
              <PDFInfo label="อีเมล">{customer?.email || '-'}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <PDFTable>
            {/* Head Table */}
            <PDFTableRow bottomLine topLine>
              <PDFTableCell head width={44} textAlign="center">
                ลำดับ
              </PDFTableCell>
              <PDFTableCell head width={72}>
                รหัสสินค้า
              </PDFTableCell>
              <PDFTableCell head width={212}>
                รายละเอียด
              </PDFTableCell>
              <PDFTableCell head width={64} textAlign="right">
                จำนวน
              </PDFTableCell>
              <PDFTableCell head width={80} textAlign="right">
                ราคาต่อหน่วย
              </PDFTableCell>
              <PDFTableCell head width={83} textAlign="right">
                จำนวนเงิน
              </PDFTableCell>
            </PDFTableRow>
            {/* Content Table */}
            {products.map((product: QuotationProfile_Product, index: number) => (
              <PDFTableRow bottomLine>
                <PDFTableCell width={44} textAlign="center">
                  {index + 1}
                </PDFTableCell>
                <PDFTableCell width={72}>{product.code}</PDFTableCell>
                <PDFTableCell width={212}>
                  <Text>{product.nameLocal.split('')}</Text>
                </PDFTableCell>
                <PDFTableCell width={64} textAlign="right">
                  {numeral(product.amount).format('0,0')} {product.unitLocal}
                </PDFTableCell>
                <PDFTableCell width={80} textAlign="right">
                  {numeral(product.price).format('0,0.00')}
                </PDFTableCell>
                <PDFTableCell width={83} textAlign="right">
                  {numeral(product.amount * product.price).format('0,0.00')}
                </PDFTableCell>
              </PDFTableRow>
            ))}
          </PDFTable>

          {/* Footer Table */}
          <PDFView bottomLine="darkGrey" style={styles.rowAndSpaceBetween}>
            <PDFView>
              <PDFView bottomLine="darkGrey" style={styles.tableFooter}>
                <PDFInfo label="ยอดเงินสุทธิ (ตัวอักษร)">{ThaiBaht(quotationProfile.total || 0)}</PDFInfo>
              </PDFView>
              <PDFView style={styles.tableFooter}>
                <PDFInfo label="หมายเหตุ" color="error">
                  {quotationProfile.remark}
                </PDFInfo>
              </PDFView>
            </PDFView>
            <PDFView style={styles.summaryBox}>
              {priceBreakdowns
                .filter((price) => {
                  return price.amount > 0 || !['ส่วนลด', 'ค่าขนส่ง'].includes(price.name)
                })
                .map((price) => (
                  <PDFInfo key={price.name} label={price.name} direction="row" dataWidth={160}>
                    {numeral(price.amount).format('0,0.00')}
                  </PDFInfo>
                ))}
            </PDFView>
          </PDFView>

          <PDFView bottomLine="lightGrey" style={{ padding: '8px 0px', fontSize: 12 }}>
            <Text>*** ซื้อครบ 499 บาท ส่งมอบสินค้าภายในกรุงเทพ และ ปริมณฑล ฟรี!!</Text>
            <Text>
              *** ต่างจังหวัด ลูกค้าเป็นผู้ออกค่าขนส่ง ทางบริษัทฯมีบริการส่งสินค้าตามบริษัทขนส่งที่ลูกค้ากำหนด
            </Text>
            <Text>
              *** กรุณาตรวจสอบรายการสินค้าก่อนการสั่งซื้อ ทางบริษัทฯไมมีนโยบายรับคืนสินค้าหากมีการเปิดใบสั่งซื้อมาแล้ว
            </Text>
            <Text>*** นโยบายการคืนสินค้า</Text>
            <Text>
              1. บริษัทฯ ยินดีรับคืนสินค้าจากปัญหาการสั่งซื้อ หรือจัดส่งผิดพลาด จากบริษัทฯ ภายใน 5 วันทำการ
              นับจากวันที่ลูกค้าได้รับมอบสินค้า โดยสินค้าและหีบห่อบรรจุภัณฑ์จะต้องอยู่ในสภาพสมบูรณ์
              ไม่มีร่องรอยการใช้งาน ทางลูกค้าต้องมีใบส่งสินค้าจากการสั่งซื้อเพื่ออ้างอิงวันที่การสั่งซื้อ ทั้งนี้
              ทางบริษัทฯ ขอสงวนสิทธิ์ไม่รับประกันสินค้า หรือ คืนสินค้าหากไม่มีใบส่งสินค้าในทุกกรณี
            </Text>
            <Text>
              2. สินค้าที่ลูกค้าสั่งผลิตตามแบบโดยเฉพาะเจาะจง หรือที่บริษัทฯ จัดหาให้ลูกค้าเป็นการเฉพาะ บริษัทฯ
              ขอสงวนสิทธิ์ในการรับคืนสินค้าดังกล่าว
            </Text>
          </PDFView>

          {quotationProfile.products.some((product) => product.warrantyDay) && (
            <PDFView bottomLine="lightGrey" style={{ padding: '8px 0px', fontSize: 14 }}>
              <Text>{'รายละเอียดการประกัน'}</Text>
              {quotationProfile.products
                .filter((p) => p.warrantyDay || p.warrantyCondition)
                .map((p) => {
                  const warrantyArray = []
                  if (p.warrantyDay) warrantyArray.push(`มีระยะเวลาประกัน ${p.warrantyDay} วัน`)
                  if (p.warrantyCondition) warrantyArray.push(`เงื่อนไขรับประกันสินค้า : ${p.warrantyCondition}`)

                  const warranty = warrantyArray.join(', ')
                  return <Text>{`${p.nameLocal} ${warranty}`}</Text>
                })}
            </PDFView>
          )}

          <PDFRow borders={['bottom']} borderColor="light" align="flex-start">
            <PDFColumn columnSpan={4}>
              <PDFInfo label="เงื่อนไขการชำระเงิน">{quotationProfile.paymentMethod.name}</PDFInfo>
            </PDFColumn>
          </PDFRow>

          <View wrap={false}>
            <PDFRow>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView title="ลงนาม/ผู้ประทับตรา ผู้เสนอราคา" />
              </PDFColumn>
              <PDFColumn columnSpan={6}>
                <PDFSignatureView title="ลงนาม ผู้รับใบเสนอราคา" />
              </PDFColumn>
            </PDFRow>

            <PDFRow style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Image src={lineContact} style={{ width: 144, height: 44, marginTop: 8, marginBottom: 8 }} />
            </PDFRow>
          </View>

          <PDFPagination />
        </Page>
        {showedImagesPage && (
          <Page size="A4" style={styles.page}>
            <Text style={styles.title} fixed>
              ภาพสินค้าทั้งหมดจากใบเสนอราคา
            </Text>

            <PDFRow align="flex-start">
              {products
                .filter((p) => !!p.imageUrl)
                .map((product: QuotationProfile_Product, index: number) => (
                  <PDFColumn columnSpan={4} key={index}>
                    <Image
                      style={styles.imageProductGallery}
                      src={{
                        uri: product.imageUrl!,
                        method: 'GET',
                        headers: { 'Cache-Control': 'no-cache' },
                        body: '',
                      }}
                    />
                    <PDFInfo label={product.code}>
                      <Text>{product.nameLocal}</Text>
                    </PDFInfo>
                  </PDFColumn>
                ))}
            </PDFRow>
          </Page>
        )}
      </Document>
    </SoraPDFViewer>
  )
}

export default QuotationsPDF
